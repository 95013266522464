import React from "react";
import { ChecksInfo, CustomerInfo } from "../../../component/info";
import { CustomerWaterFallChart } from "../../../component/chart";
import { tabStyle } from "../../../../colorSchema";

class KVC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div className={"div_scroll"} style={tabStyle}>
                <div>
                    <CustomerInfo />
                    <CustomerWaterFallChart />
                </div>
                <ChecksInfo />
            </div>
        );
    }
}

export { KVC };
