import React from "react";
import { ScatterChart } from "@aim-mf/styleguide";

class PCAChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    formatChartData(values) {
        let result = [];
        // sort data into smaller list according to cluster number
        let min_mad_cluster = Math.min.apply(
            null,
            values.map((vendor) => vendor.mad_cluster)
        );
        let max_mad_cluster = Math.max.apply(
            null,
            values.map((vendor) => vendor.mad_cluster)
        );
        values.map((value) => {
            let item = {};
            // number of vendors in the cluster
            item["x"] = value.PCA1;
            // total po amount in million
            item["y"] = value.PCA2;
            // total po number
            item["tooltipData"] = value.mad_cluster;
            let minAbsMadCluster = Math.abs(min_mad_cluster);
            let transparency = Math.round(
                ((value.mad_cluster + minAbsMadCluster) /
                    (max_mad_cluster + minAbsMadCluster)) *
                    255
            ).toString(16);
            item["color"] =
                "#00D3ED" + ("0" + transparency).slice(-2).toUpperCase();
            item["name"] = value.vendor_name;
            item["self"] = false;
            if (value.Vendor === this.props.alertDetail.alert_data_index) {
                item["self"] = true;
            }

            result.push(item);
        });
        return result;
    }

    render() {
        if (this.props.alertData.length === 0) {
            return <div />;
        }
        let data = this.formatChartData(this.props.alertData);
        return (
            <ScatterChart
                Xmin={
                    Math.min.apply(
                        null,
                        data.map((a) => a.x)
                    ) - 1
                }
                Ymin={
                    Math.min.apply(
                        null,
                        data.map((a) => a.y)
                    ) - 1
                }
                XAxisName={this.props.XAxisName ?? "PCA1"}
                YAxisName={this.props.YAxisName ?? "PCA2"}
                height={this.props.height}
                width={this.props.width}
                data={data}
                onBubbleClick={(event) => {
                    let dataIndex = event.series.data.indexOf(event.dataItem);
                    this.props.onBubbleClick(this.state.sortedData[dataIndex]);
                }}
            />
        );
    }
}

export { PCAChart };
