import React from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { mapping, dashboardStyle } from "@aim-mf/styleguide";
const HeaderRender = (props) => {
    console.log(props);
    return <span style={mapping["headerCellStyle"]}>{props.title}</span>;
};

class TransactionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Grid
                    style={{
                        maxHeight: "20rem",
                        height: "fit-content",
                        fontSize: "0.875rem",
                        marginTop: "1.25rem",
                        marginBottom: "1.25rem",
                        backgroundColor: "rgba(0,0,0,0.4)",
                    }}
                    data={this.props.transactions}
                    onRowClick={this.onRowClick}
                >
                    <Column
                        field="date"
                        title="DATE"
                        width={(140 / 18) * rem + "px"}
                    />
                    <Column
                        field="id"
                        title="TRANSACTION ID"
                        width={(180 / 18) * rem + "px"}
                    />
                    <Column
                        field="customer_id"
                        title="CUSTOMER ID"
                        width={(150 / 18) * rem + "px"}
                    />
                    <Column
                        field="account_id"
                        title="ACCOUNT ID"
                        width={(160 / 18) * rem + "px"}
                    />
                    <Column
                        field="currency"
                        title="CURRENCY CODE"
                        width={(170 / 18) * rem + "px"}
                    />
                    <Column
                        field="amount"
                        title="AMOUNT"
                        width={(120 / 18) * rem + "px"}
                    />
                    <Column
                        field="type"
                        title="DR/CR"
                        width={(100 / 18) * rem + "px"}
                    />
                    <Column field="narration" title="NARRATION" />
                    <Column field="counterparty" title="COUNTERPARTY NAME" />
                    <Column
                        field="transubtype"
                        title="TRANSUBTYPE"
                        width={(180 / 18) * rem + "px"}
                    />
                    <Column
                        field="jurisdiction"
                        title="HIGH RISK JURISDICTION"
                        width={(240 / 18) * rem + "px"}
                    />
                </Grid>
            </div>
        );
    }
}

class PastTransactionDetailsTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Grid
                    style={{
                        maxHeight: "20rem",
                        height: "fit-content",
                        fontSize: "0.875rem",
                        marginTop: "1.25rem",
                        marginBottom: "1.25rem",
                        backgroundColor: "rgba(0,0,0,0.4)",
                    }}
                    data={this.props.data}
                    onRowClick={this.onRowClick}
                    headerCellRender={HeaderRender.render}
                >
                    <Column
                        field="date"
                        title="DATE"
                        width={(140 / 18) * rem + "px"}
                    />
                    <Column
                        field="id"
                        title="TRANSACTION ID"
                        width={(200 / 18) * rem + "px"}
                    />
                    <Column
                        field="customer_id"
                        title="CUSTOMER ID"
                        width={(150 / 18) * rem + "px"}
                    />
                    <Column
                        field="account_id"
                        title="ACCOUNT ID"
                        width={(150 / 18) * rem + "px"}
                    />
                    <Column
                        field="currency"
                        title="CURRENCY CODE"
                        width={(170 / 18) * rem + "px"}
                    />
                    <Column
                        field="amount"
                        title="AMOUNT"
                        width={(100 / 16) * rem + "px"}
                    />
                    <Column
                        field="type"
                        title="DR/CR"
                        width={(100 / 16) * rem + "px"}
                    />
                    <Column field="narration" title="NARRATION" />
                    <Column field="counterparty" title="COUNTERPARTY NAME" />
                    <Column
                        field="transubtype"
                        title="TRANSUBTYPE"
                        width={(180 / 18) * rem + "px"}
                    />
                    <Column
                        field="jurisdiction"
                        title="HIGH RISK JURISDICTION"
                        width={(240 / 18) * rem + "px"}
                    />
                </Grid>
            </div>
        );
    }
}

class PastAlertDetailsTables extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <div
                    style={{
                        ...dashboardStyle.sectionTitleStyle,
                        // paddingLeft: "2rem",
                        width: "fit-content",
                    }}
                >
                    Past Alert Details
                </div>
                <Grid
                    style={{
                        maxHeight: "20rem",
                        height: "fit-content",
                        fontSize: "0.875rem",
                        marginTop: "1.25rem",
                        marginBottom: "1.25rem",
                        backgroundColor: "rgba(0,0,0,0.4)",
                    }}
                    data={this.props.data}
                    onRowClick={this.onRowClick}
                    headerCellRender={HeaderRender.render}
                >
                    <Column field="id" title="ALERT ID" />
                    <Column field="customer_id" title="CUSTOMER ID" />
                    <Column field="priority" title="PRIORITY" />
                    <Column field="alert_date" title="ALERT DATE" />
                    <Column
                        field="last_modified_date"
                        title="LAST MODIFIED DATE"
                    />
                    <Column field="status" title="STATUS" />
                </Grid>
            </div>
        );
    }
}
export {
    TransactionTable,
    PastTransactionDetailsTables,
    PastAlertDetailsTables,
};
