import { LOAD_USER, LOADING_USER } from "../constant/actionType";

const initialState = {
    loadingUser: true,
    userList: [],
};

const userReducer = (state = initialState, action) => {
    if (action.type === LOADING_USER) {
        return { ...state, loadingUser: true };
    }
    if (action.type === LOAD_USER) {
        return { ...state, loadingUser: false, userList: action.payload };
    }
    return state;
};

export default userReducer;
