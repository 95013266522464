import React from "react";
import { KVC } from "./alertCustomerSubPage/KVC";
import { Sanction } from "./alertCustomerSubPage/sanction";
import { TransactionMonitoring } from "./alertCustomerSubPage/transactionMonitoring";
import { CustomerTab } from "../../component/customerTab";
import { CustomerHeader } from "../../component/info";
import {
    customerTabMapping,
    getTransactionByAlertID,
} from "../../../api/fakeData";

class AlertCustomer extends React.Component {
    constructor(props) {
        super(props);
        let transactions = getTransactionByAlertID(this.props.alertItem.id);
        this.state = {
            option: customerTabMapping.KYC,
            transactionsList: transactions,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div>
                <div>
                    <CustomerHeader
                        customerID={this.state.transactionsList[0].customer_id}
                        status={"High Risk"}
                    />
                </div>
                <CustomerTab handleTabChange={this.handleTabChange} />
                {this.state.option === customerTabMapping.KYC && <KVC />}
                {this.state.option === customerTabMapping.sanction && (
                    <Sanction />
                )}
                {this.state.option === customerTabMapping.tm && (
                    <TransactionMonitoring vendorList={this.props.vendorList} />
                )}
            </div>
        );
    }

    handleTabChange = (value) => {
        this.setState({ option: value });
    };
}

export { AlertCustomer };
