import React from "react";
import { AlertOverview } from "./alertDetailSubPage/alertOverview";
import { AlertCustomer } from "./alertDetailSubPage/alertCustomer";
import { AlertDetailIndicator, AlertInfo } from "../component/info";
import { Tab_BookMark_TMS } from "@aim-mf/styleguide";
import { fakeUserList, tabMapping } from "../../api/fakeData";
import { CaseManagement } from "./alertDetailSubPage/caseManagement";
import { Header } from "../component/header";

class AlertDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: tabMapping.alert_overview,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        if (this.props.alertItem === undefined) {
            return <div />;
        }
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div style={{ marginLeft: "1.25rem", marginRight: "1.25rem" }}>
                <AlertInfo alertItem={this.props.alertItem} />
                <div
                    style={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        width: "100%",
                    }}
                >
                    <Tab_BookMark_TMS
                        activeBackgroundColor={"rgb(99,123,168)"}
                        BackgroundColor={"rgb(66,82,112)"}
                        // tabWidth={300}
                        height={"2rem"}
                        width={(600 / 16) * rem + "px"}
                        labels={[
                            tabMapping.alert_overview,
                            tabMapping.customer,
                            tabMapping.case_management,
                        ]}
                        OnTabChange={this.OnTabChange}
                    />
                    <AlertDetailIndicator alertItem={this.props.alertItem} />
                </div>
                {this.state.currentTab === tabMapping.alert_overview && (
                    <div
                        style={{
                            border: "1px white solid",
                            padding: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <AlertOverview alertItem={this.props.alertItem} />
                    </div>
                )}
                {this.state.currentTab === tabMapping.customer && (
                    <div
                        style={{
                            border: "1px white solid",
                            padding: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <AlertCustomer
                            alertItem={this.props.alertItem}
                            vendorList={this.props.vendorList}
                        />
                    </div>
                )}
                {this.state.currentTab === tabMapping.case_management && (
                    <div
                        style={{
                            border: "1px white solid",
                            padding: "1rem",
                            marginBottom: "1rem",
                        }}
                    >
                        <CaseManagement
                            alert={this.props.alertItem}
                            editable={true}
                            userList={fakeUserList}
                            OnPageChange={this.props.OnPageChange}
                            handleAddAction={this.props.handleAddAction}
                            handleDeleteAction={this.props.handleDeleteAction}
                            handleSelectAction={this.props.handleSelectAction}
                            onChangeTextField={this.props.onChangeTextField}
                            handleRationalChange={
                                this.props.handleRationalChange
                            }
                            handleAlertComplete={this.props.handleAlertComplete}
                            handleSaveChanges={this.props.handleSaveChanges}
                            handleUsefulChange={this.props.handleUsefulChange}
                        />
                    </div>
                )}
            </div>
        );
    }
    OnTabChange = (tab) => {
        this.setState({ currentTab: tab });
    };
}

export { AlertDetail };
