import {
    LOAD_ALERT,
    LOADING_ALERT,
    LOADING_VENDOR,
    LOAD_VENDOR,
} from "../constant/actionType";

const initialState = {
    loadingAlert: true,
    alertList: [],
};

const alertReducer = (state = initialState, action) => {
    if (action.type === LOADING_ALERT) {
        return { ...state, loadingAlert: true };
    }
    if (action.type === LOAD_ALERT) {
        // console.log(action)
        return { ...state, loadingAlert: false, alertList: action.payload };
    }
    if (action.type === LOADING_VENDOR) {
        return { ...state, loadingVendor: true };
    }
    if (action.type === LOAD_VENDOR) {
        // console.log(action)
        return { ...state, loadingVendor: false, vendorList: action.payload };
    }
    return state;
};

export default alertReducer;
