import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import userReducer from "./userReducer";
import riskReducer from "./riskReducer";

const rootReducers = combineReducers({
    alert: alertReducer,
    user: userReducer,
    risk: riskReducer,
});

export default rootReducers;
