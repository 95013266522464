import React from "react";
import {
    loadAlert,
    loadingAlert,
    loadingVendor,
    loadVendor,
} from "../redux/action/actions";
import { connect } from "react-redux";
import "./app.scss";
import "bootstrap/dist/css/bootstrap.css";
import { ScreenClassProvider } from "react-grid-system";
import axios from "axios";
import {
    getAlertList,
    fakeVendorAnalysisData,
    getAlertByID,
} from "./api/fakeData";
import { AlertDetail } from "./page/subPage/alertDetail";
import { Header } from "./page/component/header";

class App extends React.Component {
    constructor(props) {
        super(props);

        let alertItem = this.getAlert("28");
        this.state = {
            currentPage: "Overview",
            alertItem: alertItem,
        };
    }

    componentWillMount() {
        axios.defaults.headers.common["Authorization"] =
            "token " + sessionStorage.getItem("token");
        // loadAlertApiCall(
        //     this.props.loadingAlertDispatch,
        //     this.props.loadAlertDispatch
        // );
        // loadVendorApiCall(
        //     this.props.loadingVendorDispatch,
        //     this.props.loadVendorDispatch
        // )
    }

    render() {
        return (
            <ScreenClassProvider>
                <Header page={this.state.currentPage} />
                <AlertDetail
                    alertItem={this.state.alertItem}
                    vendorList={fakeVendorAnalysisData}
                    OnPageChange={this.OnPageChange}
                    handleAddAction={this.handleAddAction}
                    handleDeleteAction={this.handleDeleteAction}
                    handleSelectAction={this.handleSelectAction}
                    onChangeTextField={this.onChangeTextField}
                    handleRationalChange={this.handleRationalChange}
                    handleAlertComplete={this.handleAlertComplete}
                    handleSaveChanges={this.handleSaveChanges}
                    handleUsefulChange={this.handleUsefulChange}
                />
            </ScreenClassProvider>
        );
    }
    handleUsefulChange = (value) => {
        let alert = this.state.alertItem;
        alert.is_useful = value;
        this.setState({ alert: alert });
    };
    handleSaveChanges = () => {
        let actions = [];
        this.state.alertItem.actions.map((action, index) => {
            //console.log(action);
            actions.push({
                new_attachments: action.newAttachments,
                comment_type: action.comment_type,
                comment: action.comment,
                attachments: action.attachments,
                id: action.id,
                removeAttachmentID: action.removeAttachmentID,
            });
        });
        let alert = this.state.alertItem;
        let dataPackage = {
            alert_type: alert.alert_type,
            alerted_object_id: alert.alerted_object_id,
            rational: alert.rational,
            description: alert.description,
            is_useful: alert.is_useful === "Yes",
            due_date: alert.due_date,
            owners: alert.owners,
            risk_id: alert.risk_id,
            actions: actions,
        };
        //console.log(dataPackage);
        this.props.handleUpdateAlert({ data: dataPackage });
    };
    handleRationalChange = (value) => {
        let alert = this.state.alertItem;
        alert.rational = value;
        this.setState({ alert: alert });
    };
    handleAlertComplete = () => {
        if (this.state.alertItem.rational === "") {
            this.setState({
                warning: {
                    display: true,
                    title: "Mark as completed failed",
                    content:
                        "Rational is required to mark this alert as completed",
                    confirmFunction: () => {
                        this.setState({
                            warning: {
                                display: false,
                            },
                        });
                    },
                },
            });
        } else {
            this.setState({
                warning: {
                    display: true,
                    title: "Mark as completed",
                    content:
                        "Marking this alert as complete will close this alert, are you sure you want to close this alert?",
                    confirmFunction: () => {
                        this.props.handleCompleteAlert();
                    },
                },
            });
        }
    };
    OnPageChange = (page) => {
        this.setState({ currentPage: page });
    };
    getAlert = (id) => {
        let alert = getAlertByID(id);
        return alert;
    };
    handleAddAction = () => {
        let alert = this.state.alertItem;
        alert.actions.push({
            comment_type: "Select Form",
            comment: "",
            is_new: true,
            attachments: [],
        });
        this.setState({ alert: alert });
    };
    handleDeleteAction = (index) => {
        let alert = this.state.alertItem;
        alert.actions.splice(index, 1);
        this.setState({ alert: alert });
    };
    handleSelectAction = (index, value) => {
        if (value !== undefined) {
            let alert = this.state.alertItem;
            alert.actions[index].comment_type = value;
            this.setState({ alert: alert });
        }
    };
    onChangeTextField = (index, value) => {
        let alert = this.state.alertItem;
        alert.actions[index].comment = value;
        this.setState({ alert: alert });
    };
}

const mapStateToProps = (state) => {
    return {
        alertList: state.alert.alertList,
        loadingAlert: state.alert.loadingAlert,
        vendorList: state.alert.vendorList,
        loadingVendor: state.alert.loadingVendor,
    };
};

const mapDispatchToProps = {
    loadAlertDispatch: loadAlert,
    loadingAlertDispatch: loadingAlert,
    loadVendorDispatch: loadVendor,
    loadingVendorDispatch: loadingVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export { reloadRisk };
