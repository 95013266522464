import {
    LOAD_ALERT,
    LOAD_RISK,
    LOAD_USER,
    LOAD_VENDOR,
    LOADING_ALERT,
    LOADING_RISK,
    LOADING_USER,
    LOADING_VENDOR,
} from "../constant/actionType";

export const loadingAlert = () => {
    return { type: LOADING_ALERT };
};
export const loadAlert = (payload) => {
    return { type: LOAD_ALERT, payload };
};
export const loadingVendor = () => {
    return { type: LOADING_VENDOR };
};
export const loadVendor = (payload) => {
    return { type: LOAD_VENDOR, payload };
};
