import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";

class CounterParty extends Component {
    render() {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#00000050",
                }}
            >
                <CustomizedHorizontalBar data={data_awarded_supplier} />
            </div>
        );
    }
}

const data_awarded_supplier = {
    labels: ["CP 356", "CP 460", "CP 8", "CP 12", "CP 1028"],
    datasets: [
        {
            label: "No. of Alerts",
            backgroundColor: "#519395",
            borderColor: "#519395",
            borderWidth: 0,
            hoverBackgroundColor: "#519395",
            hoverBorderColor: "#519395",
            data: [6985928.95, 3702210.74, 2508000.0, 1716572.34, 1543317.13],
        },
    ],
};

class CustomizedHorizontalBar extends Component {
    render() {
        let data = this.props.data;

        let option = {
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            fontColor: "#FFF",
                        },
                    },
                ],
                xAxes: [
                    {
                        ticks: {
                            fontColor: "#FFF",
                            callback: function (label, index, labels) {
                                let str = label.toString();
                                str =
                                    "$" +
                                    str.substring(0, str.length - 6) +
                                    "m";
                                return str;
                            },
                        },
                        scaleLabel: {
                            display: false,
                            labelString: "Amount",
                        },
                    },
                ],
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        //console.log("1st chart data is "+data.datasets[0].data[tooltipItem.index])
                        var value = data.datasets[0].data[tooltipItem.index];
                        if (parseInt(value) >= 1000) {
                            return (
                                "$" +
                                value
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            );
                        } else {
                            return "$" + value;
                        }
                    },
                }, // end callbacks:
            }, //end tooltips
            title: {
                display: false,
                text: "Top 5 Awarded Suppliers by Award Value",
            },
            legend: {
                position: "bottom",
                display: false,
            },
        };

        return (
            <div>
                <HorizontalBar data={data} height={100} options={option} />
            </div>
        );
    }
}

export { CounterParty };
