import React from "react";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartArea,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisLabels,
    ChartTitle,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartValueAxisLabels,
} from "@progress/kendo-react-charts";
import { NetworkGraph } from "./networkGraph";
import { RelationAlertChartFromWhiteTM } from "./relationAlertChartFromWhiteTM";
import { PCAChart } from "./PCAChart";
import { HistoricalTransactionsTrending } from "./historicalTransactionsTrending";
import { CounterParty } from "./counterParty";

const orangeColor = "rgba(255,111,27,0.9)";
const blueColor = "rgba(68,114,196,0.9)";

class WaterFallChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fakeData: [
                {
                    period: "1/24/2021",
                    amount: 12000,
                },
                {
                    period: "1/25/2021",
                    amount: 4000,
                },
                {
                    period: "1/27/2021",
                    amount: 5000,
                },
                {
                    period: "1/28/2021",
                    amount: 6500,
                },
                {
                    period: "1/29/2021",
                    amount: 8000,
                },
                {
                    period: "2/12/2021",
                    amount: 7000,
                },
                {
                    period: "2/17/2021",
                    amount: 4600,
                },
                {
                    period: "2/19/2021",
                    amount: 3600,
                },
                {
                    period: "2/22/2021",
                    amount: 3000,
                },
                {
                    period: "2/25/2021",
                    amount: 11000,
                },
                {
                    period: "2/30/2021",
                    summary: "total",
                },
            ],
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div style={{ height: "20rem" }}>
                <Chart>
                    <ChartArea
                        margin={(20 / 16) * rem}
                        height={(320 / 16) * rem}
                        width={(1860 / 16) * rem}
                        background={"rgba(0,0,0,0)"}
                    />
                    <ChartTitle
                        color={"white"}
                        text="Cumulative Transactions"
                    />
                    <ChartSeries>
                        <ChartSeriesItem
                            type="waterfall"
                            data={this.state.fakeData}
                            color={this.pointColor}
                            field="amount"
                            categoryField="period"
                            summaryField="summary"
                        >
                            <ChartSeriesLabels
                                background={"rgba(0,0,0,0)"}
                                color={"white"}
                                format="C0"
                                position="outsideEnd"
                            />
                        </ChartSeriesItem>
                    </ChartSeries>
                    <ChartValueAxis>
                        <ChartValueAxisItem title={{ format: "C0" }}>
                            <ChartValueAxisLabels format="C0" color={"white"} />
                        </ChartValueAxisItem>
                    </ChartValueAxis>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            majorGridLines={{ visible: true, color: "white" }}
                            categories={this.state.category}
                        >
                            <ChartCategoryAxisLabels color={"white"} />
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                </Chart>
            </div>
        );
    }
    pointColor = (point) => {
        let summary = point.dataItem.summary;

        if (summary) {
            return "gray";
        }

        if (point.value > 0) {
            return blueColor;
        } else {
            return orangeColor;
        }
    };
}

class CustomerWaterFallChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fakeData: [
                {
                    period: "1/1/2021",
                    amount: 30000,
                },
                {
                    period: "SWIFT",
                    amount: 6000,
                },
                {
                    period: "Securities",
                    amount: 6500,
                },
                {
                    period: "Bonds",
                    amount: 10500,
                },
                {
                    period: "Cheque",
                    amount: -21600,
                },
                {
                    period: "2/30/2021",
                    summary: "total",
                },
            ],
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <div>
                <Chart style={{ height: "12.5rem" }}>
                    <ChartArea
                        height={(200 / 16) * rem}
                        width={(1088 / 16) * rem}
                        background={"rgba(0,0,0,0)"}
                    />
                    <ChartTitle
                        color={"white"}
                        text="Cumulative Transactions"
                    />
                    <ChartSeries>
                        <ChartSeriesItem
                            type="waterfall"
                            data={this.state.fakeData}
                            color={this.pointColor}
                            field="amount"
                            categoryField="period"
                            summaryField="summary"
                        >
                            <ChartSeriesLabels
                                background={"rgba(0,0,0,0)"}
                                color={"white"}
                                format="C0"
                                position="outsideEnd"
                            />
                        </ChartSeriesItem>
                    </ChartSeries>
                    <ChartValueAxis>
                        <ChartValueAxisItem title={{ format: "C0" }}>
                            <ChartValueAxisLabels format="C0" color={"white"} />
                        </ChartValueAxisItem>
                    </ChartValueAxis>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            majorGridLines={{ visible: true, color: "white" }}
                            categories={this.state.category}
                        >
                            <ChartCategoryAxisLabels color={"white"} />
                        </ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                </Chart>
            </div>
        );
    }
    pointColor = (point) => {
        let summary = point.dataItem.summary;

        if (summary) {
            return "gray";
        }

        if (point.category === "1/1/2021") {
            return "grey";
        }

        if (point.value > 0) {
            return blueColor;
        } else {
            return orangeColor;
        }
    };
}
class TransactionNetworkGraph extends React.Component {
    render() {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        marginTop: "5rem",
                        height: "21.25rem",
                        left: "calc(50% - 5px)",
                        borderRight: "5px white dashed",
                        width: 0,
                    }}
                />

                <NetworkGraph transactions={this.props.transactions} />
            </div>
        );
    }
}

class HistoricalTransactionsTrendingChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={this.props.backgroundStyle}>
                <div style={this.props.chartTitleStyle}>
                    Historical Transactions Trending
                </div>
                <div style={this.props.chartWrapper}>
                    <HistoricalTransactionsTrending />
                </div>
            </div>
        );
    }
}

class CounterparitiesChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={this.props.backgroundStyle}>
                <div style={this.props.chartTitleStyle}>
                    Historical Transactions Trending
                </div>
                <div style={this.props.chartWrapper}>
                    <CounterParty />
                </div>
            </div>
        );
    }
}

class CustomerAnomalyDetectionChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        let fakeScatterPlotHighlight = {
            alert_data_index: "100002006",
        };
        return (
            <div style={this.props.backgroundStyle}>
                <div style={this.props.chartTitleStyle}>
                    Related Accounts / Alerts
                </div>
                <div style={this.props.chartWrapper}>
                    <PCAChart
                        XAxisName={"Total no. of transactions"}
                        YAxisName={"Total Amount"}
                        alertDetail={fakeScatterPlotHighlight}
                        alertData={this.props.vendorList}
                        height={15 * rem + "px"}
                        width={"100%"}
                    />
                </div>
            </div>
        );
    }
}

class RelationAlertChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={this.props.backgroundStyle}>
                <RelationAlertChartFromWhiteTM
                    chartTitleStyle={this.props.chartTitleStyle}
                />
            </div>
        );
    }
}

export {
    WaterFallChart,
    TransactionNetworkGraph,
    RelationAlertChart,
    CustomerAnomalyDetectionChart,
    HistoricalTransactionsTrendingChart,
    CounterparitiesChart,
    CustomerWaterFallChart,
};
