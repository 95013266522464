const blueColor = "rgba(0,162,255,1)";
const alertInfoTitle = "rgba(255,255,255,0.4)";
const alertInfoContent = "rgba(255,255,255,0.9)";
const contentBackgroundColor = "rgba(0,0,0,0.2)";
const midDarkBackgroundColor = "rgba(33,41,56,1)";
const homeDarkBackgroundColor = "rgba(0,0,0,0.4)";
const DarkBackgroundColor = "rgba(0,0,0,0.7)";
const NewsDarkBackgroundColor = "rgba(0,0,0,0.6)";
const modelEvalPass = "rgb(197,224,180)";
const modelEvalFail = "rgb(255,0,0)";

const tabStyle = {
    width: "100%",
    display: "inline-flex",
    flexFlow: "row wrap",
    marginTop: "1rem",
    overflowY: "scroll",
    height: "33rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
};

export {
    modelEvalPass,
    modelEvalFail,
    homeDarkBackgroundColor,
    tabStyle,
    blueColor,
    alertInfoTitle,
    alertInfoContent,
    contentBackgroundColor,
    midDarkBackgroundColor,
    DarkBackgroundColor,
    NewsDarkBackgroundColor,
};
