import React from "react";
import { SanctionInfo } from "../../../component/info";
import { sanctionNews } from "../../../../api/fakeData";
import { tabStyle } from "../../../../colorSchema";

class Sanction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div className={"div_scroll"} style={tabStyle}>
                <SanctionInfo
                    title={"PEPs"}
                    content={["National Government – Immediate relative"]}
                />
                <SanctionInfo
                    title={"Sanction"}
                    content={["2 transaction hits with Sanctions list xyz"]}
                />
                <SanctionInfo title={"Adverse News"} content={sanctionNews} />
            </div>
        );
    }
}

export { Sanction };
