import React, { Component } from "react";
import Graph from "react-graph-vis";

class RelationAlertChartFromWhiteTM extends Component {
    render() {
        return (
            <div
                style={{ width: "100%", height: "100%", position: "relative" }}
            >
                <div style={this.props.chartTitleStyle}>
                    Related Accounts / Alerts
                </div>
                <Legend />
                <Network />
            </div>
        );
    }
}

const Network = (props) => {
    const graph = {
        nodes: [
            {
                id: 1,
                color: "#d55661",
                x: 150,
                y: -150,
                shape: "dot",
                // label: props.ID.toString(),
                label: "14",
                title: "node 1 tootip text",
                borderWidth: 0,
            },
            {
                id: 2,
                size: 15,
                color: "#30C3CD",
                x: 300,
                y: -45,
                shape: "dot",
                label: "2338",
                title: "node 2 tootip text",
                borderWidth: 0,
            },
            {
                id: 3,
                size: 15,
                color: "#30C3CD",
                x: 160,
                y: 100,
                shape: "dot",
                label: "2314",
                title: "node 3 tootip text",
                borderWidth: 0,
            },
            {
                id: 4,
                size: 15,
                color: "#767171",
                x: 40,
                y: -40,
                shape: "dot",
                label: "ABC Pte Ltd",
                title: "node 4 tootip text",
                borderWidth: 0,
            },
            {
                id: 5,
                size: 15,
                color: "#767171",
                x: -30,
                y: 50,
                shape: "dot",
                label: "Jay",
                title: "node 5 tootip text",
                borderWidth: 0,
            },
            {
                id: 6,
                size: 15,
                color: "#767171",
                x: 45,
                y: 70,
                shape: "dot",
                label: "Dave",
                title: "node 5 tootip text",
                borderWidth: 0,
            },
            {
                id: 7,
                size: 15,
                color: "#767171",
                x: 380,
                y: -70,
                shape: "dot",
                label: "May",
                title: "node 5 tootip text",
                borderWidth: 0,
            },
            {
                id: 8,
                size: 15,
                color: "#767171",
                x: 360,
                y: 0,
                shape: "dot",
                label: "Don",
                title: "node 5 tootip text",
                borderWidth: 0,
            },
            {
                id: 9,
                size: 15,
                color: "#E8804C",
                x: 250,
                y: -190,
                shape: "triangle",
                label: "CP 20198",
                title: "node 5 tootip text",
                borderWidth: 0,
            },
        ],
        edges: [
            { from: 1, to: 2 },
            { from: 1, to: 9 },
            { from: 1, to: 4 },
            { from: 2, to: 3 },
            { from: 2, to: 7 },
            { from: 2, to: 9 },
            { from: 2, to: 8 },
            { from: 3, to: 6 },
            { from: 4, to: 6 },
            { from: 4, to: 5 },
        ],
    };

    const options = {
        layout: {
            //hierarchical: true
        },
        nodes: {
            font: {
                color: "white",
                // size: 15,
                align: "center",
                bold: {
                    color: "#bbbdc0",
                    // size: 15,
                    mod: "bold",
                },
            },
        },
        edges: {
            color: "white",
        },
        interaction: {
            hover: false,
            hoverConnectedEdges: false,
            selectable: false,
            selectConnectedEdges: false,
            zoomView: false,
            dragView: false,
            dragNodes: false,
        },
        physics: { enabled: false },
    };

    const events = {
        select: function (event) {
            var { nodes, edges } = event;
        },
    };
    let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return (
        <Graph
            graph={graph}
            options={options}
            events={events}
            getNetwork={(network) => {
                //  if you want access to vis.js network api you can set the state in a parent component using this property
                // network.setSize(840/16*rem+"px", 400/16*rem+"px");
            }}
        />
    );
};

const Legend = () => {
    return (
        <div style={{ position: "absolute", right: "20px", bottom: "10px" }}>
            <div
                style={{
                    marginLeft: "10px",
                    textDecoration: "underline",
                    color: "white",
                }}
            >
                Legend
            </div>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
                <div
                    style={{
                        margin: "10px",
                        marginLeft: "5px",
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: "#d55661",
                    }}
                />
                <span style={{ color: "white" }}>Alerted Account</span>
            </div>
            <div>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div
                        style={{
                            margin: "10px",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            backgroundColor: "#30C3CD",
                        }}
                    />
                    <span style={{ color: "white" }}>Related Account</span>
                </div>
            </div>
            <div>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div
                        style={{
                            margin: "10px",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                            backgroundColor: "#767171",
                        }}
                    />
                    <span style={{ color: "white" }}>AH/BO</span>
                </div>
            </div>
            <div>
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                    <div
                        style={{
                            margin: "10px",
                            width: "0",
                            height: "0",
                            borderLeft: "8px solid transparent",
                            borderRight: "8px solid transparent",
                            borderBottom: "15px solid #E8804C",
                        }}
                    />
                </div>
                <span style={{ color: "white" }}>External Party</span>
            </div>
        </div>
    );
};

export { RelationAlertChartFromWhiteTM };
