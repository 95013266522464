import React from "react";
import ReactDOM from "react-dom";
import Graph from "react-graph-vis";

// import "./styles.css";
// need to import the vis network css in order to show tooltip
// import "./network.css";
const orangeColor = "rgba(255,111,27,0.9)";
const yellowColor = "rgba(255,192,0,0.9)";
const greenColor = "rgba(112,173,71,0.9)";
const blueColor = "rgba(68,114,196,0.9)";
const purple = "rgba(180,0,124,0.9)";
const cyn = "rgba(12,172,168,0.9)";

const colorList = {
    "WEST TAILORS": orangeColor,
    "DAVID SMITH": cyn,
    "ABC MOTORS": yellowColor,
    "TIM CARLOS": purple,
    "XJSF PTE LTD": blueColor,
    "SARAH LEE": greenColor,
};

let rem = parseFloat(getComputedStyle(document.documentElement).fontSize);

let options = {
    layout: {
        randomSeed: 2,
    },
    nodes: {
        fixed: {
            x: true,
            y: false,
        },
        scaling: {
            customScalingFunction: function (min, max, total, value) {
                if (max === min) {
                    return 0.5;
                } else {
                    var scale = 1 / (max - min);
                    return Math.max(0, (value - min) * scale);
                }
            },
            min: (35 / 16) * rem,
            max: (45 / 16) * rem,
        },
        shape: "circle",
        borderWidth: 1.5,
        borderWidthSelected: 2,
        font: {
            color: "white",
            size: (10 / 18) * rem,
            align: "center",
            bold: {
                color: "#bbbdc0",
                mod: "bold",
            },
        },
    },
    edges: {
        width: 0.02,
        color: {
            color: "#D3D3D3",
            highlight: "#797979",
            hover: "#797979",
            opacity: 1.0,
        },
        // scaling: {
        //     customScalingFunction: function (min, max, total, value) {
        //         if (max === min) {
        //             return 0.5;
        //         } else {
        //             var scale = 1 / (max - min);
        //             return Math.max(0, (value - min) * scale);
        //         }
        //     },
        //     min: 1,
        //     max: 200,
        // },
        arrows: {
            to: { enabled: true, scaleFactor: (1 / 18) * rem, type: "arrow" },
            middle: { enabled: false, scaleFactor: 1, type: "arrow" },
            from: { enabled: false, scaleFactor: 1, type: "arrow" },
        },
        // arrowStrikethrough: true,
        // endPointOffset:{
        //     from:-10,
        //     to:-10
        // }
    },
    physics: {
        barnesHut: {
            gravitationalConstant: (-20000 / 18) * rem,
            centralGravity: 2,
            springLength: (100 / 18) * rem,
            avoidOverlap: 1,
        },
        stabilization: { iterations: 1 },
    },
    interaction: {
        hover: false,
        hoverConnectedEdges: false,
        selectable: false,
        selectConnectedEdges: false,
        zoomView: false,
        dragView: false,
        dragNodes: false,
    },
};

const events = {
    select: function (event) {
        var { nodes, edges } = event;
    },
};

class NetworkGraph extends React.Component {
    constructor() {
        super();
        this.state = {
            graph: { nodes: [], edges: [] },
        };
    }
    componentDidMount() {
        if (this.props.transactions.length === 0) {
            console.log("empty transactions");
            return;
        }
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );

        let nodes = [];
        let edges = [];
        let rootNode = {
            id: 0,
            label: "Jane Doe’s\n    Transactions     \nFY21",
            // value: 34/16*rem,
            color: "grey",
            x: 0,
        };
        nodes.push(rootNode);
        this.props.transactions.map((t, i) => {
            nodes.push({
                id: i + 1,
                label: t.counterparty + "\n" + t.amount,
                // value: parseFloat(t.amount)/60/16*rem,
                color: colorList[t.counterparty.trim()],
                // value: 100,
                x:
                    t.type === "DR"
                        ? Math.random() * ((600 / 16) * rem) + (100 / 16) * rem
                        : Math.random() * ((-600 / 16) * rem) -
                          (100 / 16) * rem,
            });
            if (t.type === "DR") edges.push({ from: 0, to: i + 1 });

            if (t.type === "CR") edges.push({ from: i + 1, to: 0 });
        });
        console.log({ nodes: nodes, edges: edges });
        this.setState({ graph: { nodes: nodes, edges: edges } });
    }
    render() {
        if (this.props.transactions.length === 0) {
            return <div />;
        }
        let rem = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        return (
            <Graph
                graph={this.state.graph}
                options={{ ...options, height: (500 / 16) * rem + "px" }}
                events={events}
                getNetwork={(network) => {
                    //  if you want access to vis.js network api you can set the state in a parent component using this property
                }}
            />
        );
    }
}

export { NetworkGraph };
