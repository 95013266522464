import React, { Component } from "react";
import { Line } from "react-chartjs-2";

const data = {
    labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ],
    datasets: [
        {
            type: "line",
            label: "No. of transactions",
            data: [200, 185, 590, 621, 250, 400, 195, 208, 309, 401, 103, 293],
            fill: false,
            backgroundColor: "#8DC6BF",
            borderColor: "#8DC6BF",
            hoverBackgroundColor: "#8DC6BF",
            hoverBorderColor: "#8DC6BF",
            yAxisID: "y-axis-1",
        },
        {
            label: "Transaction amount",
            type: "bar",
            data: [
                17000000.0, 11000000.0, 17000000.0, 12000000.0, 37000000.0,
                20000000.0, 10000000.0, 13000000.0, 11000000.0, 6600000.0,
                16000000.0, 14000000.0,
            ],
            fill: false,
            borderColor: "#EC932F",
            backgroundColor: "#EC932F",
            pointBorderColor: "#EC932F",
            pointBackgroundColor: "#EC932F",
            pointHoverBackgroundColor: "#EC932F",
            pointHoverBorderColor: "#EC932F",
            yAxisID: "y-axis-2",
        },
    ],
};

const options = {
    legend: {
        labels: {
            fontColor: "white",
        },
    },
    responsive: true,
    tooltips: {
        mode: "label",
    },
    plugins: {
        datalabels: {
            display: false,
        },
    },
    elements: {
        line: {
            tension: 0,
            fill: false,
        },
        point: {
            radius: 0,
            hitRadius: 5,
            hoverRadius: 5,
        },
    },
    scales: {
        ticks: {
            fontColor: "#FFF",
        },
        xAxes: [
            {
                display: true,
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontColor: "#FFF",
                },
                //labels: {
                //    show: true
                //}
            },
        ],
        yAxes: [
            {
                type: "linear",
                display: true,
                position: "left",
                id: "y-axis-1",
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontColor: "#FFF",
                },
                //labels: {
                //    show: true
                //}
            },
            {
                type: "linear",
                display: true,
                position: "right",
                id: "y-axis-2",
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontColor: "#FFF",
                },
                //labels: {
                //    show: true
                //}
            },
        ],
    },
};

class HistoricalTransactionsTrending extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
        };
    }

    render() {
        return (
            <div
                style={{
                    width: "100%",
                    height: "15rem",
                    backgroundColor: "#00000050",
                }}
            >
                <Line data={data} options={options} height={100} />
            </div>
        );
    }
}

export { HistoricalTransactionsTrending };
