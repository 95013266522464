import React from "react";
import {
    customerInfoContent,
    customerInfoHeaderMapping,
} from "../../api/fakeData";
import { IconSVG, CustomDatePicker } from "@aim-mf/styleguide";
import {
    blueColor,
    contentBackgroundColor,
    midDarkBackgroundColor,
    DarkBackgroundColor,
    NewsDarkBackgroundColor,
    alertInfoTitle,
    alertInfoContent,
} from "../../colorSchema";

const checkTitleStyle = {
    padding: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    color: "white",
    width: "100%",
    backgroundColor: DarkBackgroundColor,
};
const checkContentStyle = {
    color: "white",
    marginBottom: "1rem",
    padding: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    display: "inline-flex",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: contentBackgroundColor,
};

class AlertInfo extends React.Component {
    render() {
        return (
            <div style={{ display: "inline-flex", width: "100%" }}>
                <span style={{ margin: "1rem", color: alertInfoTitle }}>
                    Alert ID:{" "}
                    <span style={{ color: alertInfoContent }}>
                        {this.props.alertItem.id}
                    </span>
                </span>
                <span style={{ margin: "1rem", color: alertInfoTitle }}>
                    Status:{" "}
                    <span style={{ color: alertInfoContent }}>
                        {this.props.alertItem.status}
                    </span>
                </span>
                <span style={{ margin: "1rem", color: alertInfoTitle }}>
                    Anomaly Score:{" "}
                    <span style={{ color: alertInfoContent }}>
                        {this.props.alertItem.anomaly_score}
                    </span>
                </span>
                <span style={{ margin: "1rem", color: alertInfoTitle }}>
                    Priority:{" "}
                    <span style={{ color: alertInfoContent }}>
                        {this.props.alertItem.priority}
                    </span>
                </span>
                <span style={{ margin: "1rem", color: alertInfoTitle }}>
                    Created Date:{" "}
                    <span style={{ color: alertInfoContent }}>
                        {this.props.alertItem.alert_date}
                    </span>
                </span>
                <span style={{ margin: "1rem", color: alertInfoTitle }}>
                    Similarity to past STRs:{" "}
                    <span style={{ color: alertInfoContent }}>
                        {this.props.alertItem.similarity}
                    </span>
                </span>
            </div>
        );
    }
}

class CustomerHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={{ display: "inline-flex" }}>
                <span
                    style={{
                        fontSize: "1.3rem",
                        fontWeight: "bold",
                        height: "3rem",
                        width: "fit-content",
                        color: "white",
                        textAlign: "center",
                        lineHeight: "3rem",
                    }}
                >
                    Jane Doe (ID: {this.props.customerID})
                </span>
                <div
                    style={{
                        margin: "0.75rem",
                        height: "1.5rem",
                        width: "8rem",
                        backgroundColor: "red",
                        color: "white",
                        textAlign: "center",
                        lineHeight: "1.5rem",
                        borderRadius: "5%",
                    }}
                >
                    {this.props.status}
                </div>
            </div>
        );
    }
}
class CustomerInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    infoRowStyle = {
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%",
    };
    infoHeaderStyle = {
        display: "inline-flex",
        color: "grey",
        width: "16rem",
    };
    infoContentStyle = {
        display: "inline-flex",
        color: "white",
        width: "16rem",
    };
    render() {
        return (
            <div
                style={{
                    display: "inline-flex",
                    margin: "2rem",
                    height: "fit-content",
                }}
            >
                <div
                    style={{
                        height: "fit-content",
                        width: "32rem",
                        marginRight: "4rem",
                    }}
                >
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.full_name}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.full_name}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.aliases}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.aliases}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.identity}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.identity}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.gender}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.gender}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.marital}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.marital}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.date}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.date}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.place}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.place}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.nationality}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.nationality}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.occupation}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.occupation}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.industry}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.industry}
                        </div>
                    </div>
                </div>
                <div style={{ height: "fit-content", width: "32rem" }}>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.pep}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.pep}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.aliases_name}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.aliases_name}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.mobile_number}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.mobile_number}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.residential_address}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.residential_address}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.expected_aum}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.expected_aum}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.current_aum}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.current_aum}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.account_opening_date}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.account_opening_date}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.relationship_manager}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.relationship_manager}
                        </div>
                    </div>
                    <div style={this.infoRowStyle}>
                        <div style={this.infoHeaderStyle}>
                            {customerInfoHeaderMapping.source_of_wealth}
                        </div>
                        <div style={this.infoContentStyle}>
                            {customerInfoContent.source_of_wealth}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
class SanctionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={{ width: "100%" }}>
                <div style={checkTitleStyle}>
                    <div style={{ display: "inline-flex", color: "white" }}>
                        {this.props.title}
                    </div>
                    <div style={this.numberIndicator}>
                        {this.props.content.length}
                    </div>
                </div>
                {this.props.title !== "Adverse News" &&
                    this.props.content.map((content, index) => {
                        return (
                            <div style={checkContentStyle}>
                                {index + 1}. {content}
                            </div>
                        );
                    })}
                {this.props.title === "Adverse News" && (
                    <div
                        style={{
                            ...checkContentStyle,
                            display: "inline-flex",
                            padding: "0.5rem",
                            paddingTop: "0.5rem",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: midDarkBackgroundColor,
                                height: "20rem",
                                width: "12rem",
                                margin: "1rem",
                                padding: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "1.5rem",
                                    marginBottom: "1.5rem",
                                }}
                            >
                                Sort
                            </div>
                            <div
                                style={{
                                    fontSize: "0.8rem",
                                    textDecoration: "underLine",
                                }}
                            >
                                Most Relevant
                            </div>
                            <div
                                style={{
                                    fontSize: "0.8rem",
                                    textDecoration: "underLine",
                                    color: blueColor,
                                }}
                            >
                                Newest
                            </div>
                            <div
                                style={{
                                    fontSize: "0.8rem",
                                    textDecoration: "underLine",
                                    marginBottom: "1.5rem",
                                }}
                            >
                                Oldest
                            </div>
                            <div style={{ fontSize: "1.2rem" }}>
                                Filter by date
                            </div>
                            <CustomDatePicker value={"04/10/2021"} />
                            <div>To</div>
                            <CustomDatePicker value={new Date()} />
                        </div>
                        <div
                            className={"div_scroll"}
                            style={{
                                overflowY: "scroll",
                                height: "20rem",
                                flex: 1,
                                margin: "1rem",
                            }}
                        >
                            {this.props.content.map((content) => {
                                return (
                                    <div
                                        style={{
                                            backgroundColor:
                                                NewsDarkBackgroundColor,
                                            width: "100%",
                                            height: "9.5rem",
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        <div style={{ display: "inline-flex" }}>
                                            <div
                                                style={{
                                                    width: "9.5rem",
                                                    height: "9.5rem",
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "auto",
                                                        width: "6rem",
                                                        height: "6rem",
                                                    }}
                                                >
                                                    {/* 6 = 4 * 1.5 */}
                                                    <IconSVG
                                                        name={"file"}
                                                        size={4}
                                                        color={blueColor}
                                                        center={"left_top"}
                                                        wrapperStyle={{
                                                            height: "fit-content",
                                                        }}
                                                        // onClick={()=>{
                                                        //     console.log("123")
                                                        // }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    zIndex: "10",
                                                    padding: "1rem",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        color: "white",
                                                        fontSize: "1.5rem",
                                                    }}
                                                >
                                                    {content.title}
                                                </div>
                                                <div
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "0.8rem",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Publication Date:{" "}
                                                    <span
                                                        style={{
                                                            fontWeight:
                                                                "normal",
                                                        }}
                                                    >
                                                        {content.date}
                                                    </span>
                                                </div>
                                                <div
                                                    style={{
                                                        color: "grey",
                                                        fontSize: "0.8rem",
                                                        marginTop: "2rem",
                                                    }}
                                                >
                                                    {content.content}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }
    numberIndicator = {
        display: "inline-flex",
        marginLeft: "0.5rem",
        justifyContent: "center",
        color: blueColor,
        backgroundColor: midDarkBackgroundColor,
        width: "1.5rem",
        height: "1.5rem",
        lineHeight: "1.5rem",
    };
}

class ChecksInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div
                style={{
                    marginLeft: "5rem",
                    marginTop: "2rem",
                    width: " 30rem",
                    height: "fit-content",
                }}
            >
                <div style={{ width: "100%" }}>
                    <div style={checkTitleStyle}>
                        KYC Identity Verification Check
                    </div>
                    <div style={checkContentStyle}>
                        <div style={{ color: "white" }}>2*2 Safe Harbor</div>
                        <div style={{ color: blueColor }}>Risk: 0</div>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <div style={checkTitleStyle}>Jurisdiction</div>
                    <div style={checkContentStyle}>
                        <div style={{ color: "white" }}>
                            Non-FATF Member, Corruption = High
                        </div>
                        <div style={{ color: blueColor }}>Risk: 7</div>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <div style={checkTitleStyle}>
                        Watchlist and Sanctions Check
                    </div>
                    <div style={checkContentStyle}>
                        <div style={{ color: "white" }}>1 Match (s)</div>
                        <div style={{ color: blueColor }}>Risk: 5</div>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <div style={checkTitleStyle}>PEP Check</div>
                    <div style={checkContentStyle}>
                        <div style={{ color: "white" }}>Negative</div>
                        <div style={{ color: blueColor }}>Risk: 0</div>
                    </div>
                </div>
                <div style={{ width: "100%" }}>
                    <div style={checkTitleStyle}>Enhanced Due Diligence</div>
                    <div style={checkContentStyle}>
                        <div style={{ color: "white" }}>
                            Determined Source of Funds
                        </div>
                        <div style={{ color: blueColor }}>Risk: 1</div>
                    </div>
                </div>
            </div>
        );
    }
}

class AlertDetailIndicator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={{ display: "inline-flex" }}>
                <div style={{ marginRight: "1rem" }}>
                    <span
                        style={{
                            lineHeight: "1rem",
                            display: "block",
                            color: alertInfoTitle,
                            textAlign: "end",
                        }}
                    >
                        Last Modified
                    </span>
                    <span
                        style={{
                            lineHeight: "1rem",
                            display: "block",
                            color: alertInfoContent,
                            textAlign: "end",
                        }}
                    >
                        {this.props.alertItem.last_modified_date +
                            " " +
                            this.props.alertItem.last_modified_time}
                    </span>
                </div>
                <span
                    style={{
                        height: "2rem",
                        width: "8rem",
                        backgroundColor: "orange",
                        color: "white",
                        textAlign: "center",
                        lineHeight: "2rem",
                        borderRadius: "5%",
                        marginBottom: "1rem",
                    }}
                >
                    {this.props.alertItem.status}
                </span>
            </div>
        );
    }
}

class KeyStatisticsStructuring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return <div></div>;
    }
}

class KeyStatisticsFlowThrough extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return <div></div>;
    }
}
export {
    AlertInfo,
    CustomerInfo,
    SanctionInfo,
    ChecksInfo,
    AlertDetailIndicator,
    KeyStatisticsStructuring,
    KeyStatisticsFlowThrough,
    CustomerHeader,
};
