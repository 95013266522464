import React from "react";
import { customerTabMapping } from "../../api/fakeData";

const tabFontStyle = {
    height: "1rem",
    width: "fit-content",
    color: "white",
    textAlign: "center",
    lineHeight: "1rem",
};
const tabRiskTagStyle = {
    fontSize: "1rem",
    marginLeft: "0.8rem",
    height: "1.2rem",
    width: "4rem",
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    lineHeight: "1rem",
    borderRadius: "5%",
};
class CustomerTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indicationBarLeft: "7rem",
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div style={{ marginTop: "1rem" }}>
                <div
                    style={{
                        display: "inline-flex",
                        justifyContent: "space-around",
                        width: "100%",
                    }}
                >
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                        style={{ display: "inline-flex", width: "fit-content" }}
                        onClick={() => {
                            this.setState({ indicationBarLeft: "7rem" });
                            this.props.handleTabChange(customerTabMapping.KYC);
                        }}
                    >
                        <span style={tabFontStyle}>
                            {customerTabMapping.KYC}
                        </span>
                        <div style={tabRiskTagStyle}>High</div>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                        style={{ display: "inline-flex", width: "fit-content" }}
                        onClick={() => {
                            this.setState({ indicationBarLeft: "43rem" });
                            this.props.handleTabChange(
                                customerTabMapping.sanction
                            );
                        }}
                    >
                        <span style={tabFontStyle}>
                            {customerTabMapping.sanction}
                        </span>
                        <div
                            style={{
                                ...tabRiskTagStyle,
                                backgroundColor: "green",
                            }}
                        >
                            Low
                        </div>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                        style={{ display: "inline-flex", width: "fit-content" }}
                        onClick={() => {
                            this.setState({ indicationBarLeft: "83rem" });
                            this.props.handleTabChange(customerTabMapping.tm);
                        }}
                    >
                        <span style={{ ...tabFontStyle, whiteSpace: "nowrap" }}>
                            {customerTabMapping.tm}
                        </span>
                        <div
                            style={{
                                ...tabRiskTagStyle,
                                backgroundColor: "green",
                            }}
                        >
                            Low
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        marginLeft: this.state.indicationBarLeft,
                        backgroundColor: "rgba(0,162,255,1)",
                        width: "20rem",
                        height: "0.3em",
                        marginTop: "1rem",
                        transition:
                            "margin-left 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                    }}
                />
                <div
                    style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(255,255,255,0.5)",
                    }}
                />
            </div>
        );
    }
}

export { CustomerTab };
