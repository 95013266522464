import React from "react";
import { TransactionTable } from "../../component/table";
import { TransactionNetworkGraph, WaterFallChart } from "../../component/chart";
import {
    alertOverviewDropDownMapping,
    alertOverviewDropDownRedFlagMapping,
    alertOverviewDropDownTreeData,
    getTransactionByAlertID,
} from "../../../api/fakeData";
import { DropDownTreeButton } from "@aim-mf/styleguide";
import {
    KeyStatisticsFlowThrough,
    KeyStatisticsStructuring,
} from "../../component/info";

class AlertOverview extends React.Component {
    constructor(props) {
        super(props);
        let transactions = getTransactionByAlertID(this.props.alertItem.id);
        this.state = {
            options: alertOverviewDropDownMapping.overview,
            transactionsList: transactions,
            transactions: transactions,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        let dropdownData = alertOverviewDropDownTreeData;
        return (
            <div>
                <div style={{ position: "absolute", top: "12rem", zIndex: 10 }}>
                    <span style={{ color: "white" }}>
                        {this.state.options ===
                        alertOverviewDropDownMapping.overview
                            ? this.state.options
                            : alertOverviewDropDownMapping.redFlag +
                              " > " +
                              this.state.options}
                    </span>
                    <DropDownTreeButton
                        DropDownButtonStyle={{ width: "15rem" }}
                        data={dropdownData}
                        value={dropdownData[0]}
                        CustomOnChange={this.dropDownOnChange}
                        effectingLabel={[
                            alertOverviewDropDownMapping.overview,
                            alertOverviewDropDownRedFlagMapping.structuring,
                            alertOverviewDropDownRedFlagMapping.flowThrough,
                        ]}
                    />
                </div>
                <div>
                    {this.state.options ===
                        alertOverviewDropDownMapping.overview && (
                        <TransactionNetworkGraph
                            transactions={this.state.transactionsList}
                        />
                    )}

                    {/*structuring special component*/}
                    {this.state.options ===
                        alertOverviewDropDownRedFlagMapping.structuring && (
                        <TransactionNetworkGraph
                            transactions={this.state.transactionsList.filter(
                                (t) => {
                                    return (
                                        t.counterparty.trim() ===
                                        "XJSF PTE LTD".trim()
                                    );
                                }
                            )}
                        />
                    )}
                    {this.state.options ===
                        alertOverviewDropDownRedFlagMapping.structuring && (
                        <KeyStatisticsStructuring />
                    )}
                    {this.state.options ===
                        alertOverviewDropDownRedFlagMapping.structuring && (
                        <WaterFallChart />
                    )}

                    {/*Flow Through special component*/}
                    {this.state.options ===
                        alertOverviewDropDownRedFlagMapping.flowThrough && (
                        <TransactionNetworkGraph
                            transactions={this.state.transactionsList.filter(
                                (t) => {
                                    return [
                                        "WEST TAILORS",
                                        "ABC MOTORS",
                                    ].includes(t.counterparty.trim());
                                }
                            )}
                        />
                    )}
                    {this.state.options ===
                        alertOverviewDropDownRedFlagMapping.flowThrough && (
                        <KeyStatisticsFlowThrough />
                    )}
                </div>
                <TransactionTable transactions={this.state.transactions} />
            </div>
        );
    }
    dropDownOnChange = (event) => {
        let transactions;
        console.log(event);
        if (
            event.value.text === alertOverviewDropDownRedFlagMapping.structuring
        ) {
            transactions = this.state.transactionsList.filter((t) => {
                return t.counterparty.trim() === "XJSF PTE LTD".trim();
            });
        } else if (
            event.value.text === alertOverviewDropDownRedFlagMapping.flowThrough
        ) {
            transactions = this.state.transactionsList.filter((t) => {
                return ["WEST TAILORS", "ABC MOTORS"].includes(
                    t.counterparty.trim()
                );
            });
        } else {
            transactions = this.state.transactionsList;
        }
        this.setState({
            options: event.value.text,
            transactions: transactions,
        });
    };
}

export { AlertOverview };
