import React from "react";
import {
    mapping,
    NameCard,
    AutoSizeTextArea,
    RadioButtonGroup,
    IconButtonGhost,
    IconSVG,
    ActionDefaultTemplate,
    ActionTextInput,
    ActionUploadRequest,
    ButtonSolid,
    alertManagementStyle,
} from "@aim-mf/styleguide";

class CaseManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            createTask: false,
            Confirmation: { display: false },
        };
    }

    render() {
        //console.log(this.props.alert);
        let status = "";
        let dueDays = Math.ceil(
            (new Date(this.props.alert.due_date) - new Date()) /
                (1000 * 60 * 60 * 24)
        );

        if (dueDays > 0) {
            status = "Due in " + dueDays + " days";
        } else if (dueDays === 0) {
            status = "Due Today";
        } else {
            status = "Overdue " + -dueDays + " days";
        }

        if (this.props.alert.status === "closed") {
            status = "Closed";
        }

        // let TR1 = false, //transfer request (alert owner side)
        //     TR2 = false, // transfer received {recipient side}
        //     TA = false, // transfer accepted
        //     TD = false, // transfer declined
        //     RR = false, // report request
        //     RR1 = false, // report request (alert owner side)
        //     alert = this.props.alert,
        //     me = this.props.currentUser.username;
        //
        // let transferMessage = alert.transfer_messages.filter((msg) => {
        //     return msg.msg_type === "transfer";
        // });
        //
        // let alertMessage = alert.transfer_messages.filter((msg) => {
        //     return msg.msg_type === "report";
        // });
        //
        // if (alert.owners.includes(me)) {
        //     if (
        //         alertMessage.length !== 0 &&
        //         alertMessage[alertMessage.length - 1].to_user !== me
        //     ) {
        //         RR1 = true;
        //     }
        //     if (alert.creator !== "SYSTEM") {
        //         // some body report the alert to manager, so the creator is not system
        //         RR = true;
        //     } else if (alert.transferees.length !== 0) {
        //         // if transferees is not empty, meaning the alert is transfer request stage
        //         TR1 = true;
        //     } else if (
        //         transferMessage.length !== 0 &&
        //         transferMessage[transferMessage.length - 1].from_user !==
        //             alert.past_owners[alert.past_owners.length - 1]
        //     ) {
        //         // if the last transfer message is not from the last past owner, it means the transfer request is declined
        //         TD = true;
        //     }
        // } else if (alert.transferees.includes(me)) {
        //     // if i am in the transfer list, somebody has request to transfer to me
        //     TR2 = true;
        // } else if (alert.past_owners.includes(me)) {
        //     if (alert.transferees.length !== 0) {
        //         // if i am the past owner, and the transfer list is not empty, it means the alert is in transfer state
        //         TR1 = true;
        //     } else {
        //         // if i am the past owner, and transfer list is empty, he/she has accepted the alert
        //         TA = true;
        //     }
        // }

        //console.log(this.props.alert);
        if (Object.keys(this.props.alert).length === 0) {
            return <div />;
        }
        // console.log(alert);
        return (
            <div
                style={{
                    ...alertManagementStyle.wrapperStyle,
                    width: "100%",
                    padding: "1rem",
                }}
            >
                <div
                    style={{
                        color: "white",
                        position: "absolute",
                        right: "0.625rem",
                        top: "0.625rem",
                    }}
                >
                    <div
                        style={{
                            ...alertManagementStyle.titleStyle,
                            color: mapping["Color_Basic_Success"],
                        }}
                    >
                        {this.props.alert.status === "closed" ? "Complete" : ""}
                    </div>
                </div>
                <div style={alertManagementStyle.titleStyle}>
                    Alert ID: {this.props.alert.id}
                </div>
                {/*{RR1 && (*/}
                {/*    <ReportAlertRequestAlertOwnerSide*/}
                {/*        userList={this.props.userList}*/}
                {/*        alert={this.props.alert}*/}
                {/*        msgList={alertMessage}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{TR2 && (*/}
                {/*    <ReceiveTransferRequest*/}
                {/*        userList={this.props.userList}*/}
                {/*        alert={this.props.alert}*/}
                {/*        message={transferMessage[transferMessage.length - 1]}*/}
                {/*        currentUser={this.props.currentUser}*/}
                {/*        handleAcceptTransferAlert={*/}
                {/*            this.props.handleAcceptTransferAlert*/}
                {/*        }*/}
                {/*        handleDeclineTransferAlert={*/}
                {/*            this.props.handleDeclineTransferAlert*/}
                {/*        }*/}
                {/*        handleConfirmation={this.props.handleConfirmation}*/}
                {/*        handleDeclineTransferPopup={*/}
                {/*            this.props.handleDeclineTransferPopup*/}
                {/*        }*/}
                {/*    />*/}
                {/*)}*/}
                {/*{TR1 && (*/}
                {/*    <TransferRequested*/}
                {/*        message={transferMessage[transferMessage.length - 1]}*/}
                {/*        currentUser={this.props.currentUser}*/}
                {/*        userList={this.props.userList}*/}
                {/*        alert={this.props.alert}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{TD && (*/}
                {/*    <TransferRequestRejected*/}
                {/*        message={transferMessage[transferMessage.length - 1]}*/}
                {/*        currentUser={this.props.currentUser}*/}
                {/*        userList={this.props.userList}*/}
                {/*        alert={this.props.alert}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{TA && (*/}
                {/*    <TransferRequestAccepted*/}
                {/*        message={transferMessage[transferMessage.length - 1]}*/}
                {/*        userList={this.props.userList}*/}
                {/*        alert={this.props.alert}*/}
                {/*    />*/}
                {/*)}*/}
                {/*{RR && (*/}
                {/*    <ReportAlertRequest*/}
                {/*        userList={this.props.userList}*/}
                {/*        alert={this.props.alert}*/}
                {/*    />*/}
                {/*)}*/}
                <div
                    style={{
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <OneItemPerLine
                        data={{
                            title: "Description",
                            content: this.props.alert.description,
                        }}
                    />
                    <TwoItemPerLine
                        data={[
                            { title: "Status", content: status },
                            {
                                title: "Due Date",
                                content: this.props.alert.due_date,
                            },
                        ]}
                    />
                    <RiskOwner
                        risk_owner={this.props.alert.owners}
                        userList={this.props.userList}
                    />
                </div>
                <div
                    style={{
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                >
                    <AlertedParty />
                    <Rational
                        description={this.props.alert.rational}
                        handleChange={this.props.handleRationalChange}
                        editable={this.props.editable}
                    />
                    <Useful
                        is_useful={this.props.alert.is_useful}
                        handleUsefulChange={this.props.handleUsefulChange}
                        editable={this.props.editable}
                    />
                </div>
                <Action
                    actions={this.props.alert.actions}
                    handleDeleteAction={this.props.handleDeleteAction}
                    handleSelectAction={this.props.handleSelectAction}
                    onChangeTextField={this.props.onChangeTextField}
                    handleRadioSelection={this.props.handleRadioSelection}
                    onChangeOptionInput={this.props.onChangeOptionInput}
                    handleAddOption={this.props.handleAddOption}
                    handleDeleteOption={this.props.handleDeleteOption}
                    handleAttachmentChange={this.props.handleAttachmentChange}
                    handleRemoveUploadFile={this.props.handleRemoveUploadFile}
                    handleAttachmentDownload={
                        this.props.handleAttachmentDownload
                    }
                    editable={this.props.editable}
                />
                <div style={{ height: "2.5rem" }} />
                <div
                    style={{
                        display: "inline-flex",
                        width: "100%",
                        justifyContent: "Center",
                    }}
                >
                    <IconButtonGhost
                        name={"Support Document"}
                        fontStyle={{ color: "white" }}
                        height={"2.5rem"}
                        width={"12rem"}
                        color={"Primary"}
                        iconPos={"Right"}
                        icon={
                            <IconSVG
                                name={"newRisk"}
                                color={"white"}
                                size={1}
                            />
                        }
                        clickEvent={this.props.handleAddAction}
                        disabled={!this.props.editable}
                    />
                    <div style={{ width: "1rem" }} />
                    <ButtonSolid
                        name={"Save Changes"}
                        height={alertManagementStyle.basicInfoButtonHeight}
                        width={alertManagementStyle.basicInfoButtonWidth}
                        clickEvent={this.props.handleSaveChanges}
                        disabled={!this.props.editable}
                    />
                    <div style={{ width: "1rem" }} />
                    <ButtonSolid
                        name={"Mark as complete"}
                        fontStyle={{ color: "white" }}
                        height={alertManagementStyle.basicInfoButtonHeight}
                        width={alertManagementStyle.basicInfoButtonWidth}
                        clickEvent={this.props.handleAlertComplete}
                        disabled={!this.props.editable}
                    />
                </div>
                <div style={{ height: "2.5rem" }} />
            </div>
        );
    }
}
const RiskOwner = (props) => {
    return (
        <div style={{ width: "33%" }}>
            <div style={mapping["forms/label/1-default"]}>Alert Owner</div>
            {props.risk_owner.map((username, index) => {
                let owner = props.userList.filter((user) => {
                    return user.username === username;
                })[0];
                if (Object.keys(owner).length === 0) {
                    return <div />;
                } else {
                    return (
                        <div
                            style={{
                                marginRight: "1rem",
                                display: "inline-flex",
                            }}
                        >
                            <NameCard
                                key={owner.display_name + owner.title}
                                initial={owner.display_name
                                    .split(" ")
                                    .map((a) => {
                                        return a[0].toUpperCase();
                                    })
                                    .join("")}
                                name={owner.display_name}
                                title={owner.title}
                            />
                        </div>
                    );
                }
            })}

            <div style={{ height: "1.75rem" }} />
        </div>
    );
};

const AlertedParty = (props) => {
    return (
        <div style={{ width: "33%" }}>
            <div style={mapping["forms/label/1-default"]}>Alerted Party</div>
            <div
                style={{
                    marginRight: "1rem",
                    display: "inline-flex",
                }}
            >
                <NameCard
                    key={"Alerted_Party"}
                    initial={"JD"}
                    name={"Jane Doe"}
                    title={"OE17000499"}
                />
            </div>
            <div style={{ height: "1.75rem" }} />
        </div>
    );
};
const Rational = (props) => {
    const handleChange = (value) => {
        props.handleChange(value);
    };
    return (
        <div style={{ width: "33%" }}>
            <div style={mapping["forms/label/1-default"]}>Rational</div>
            <TextInput
                minRows={3}
                width={"33.75rem"}
                value={props.description}
                onCustomChange={handleChange}
                disabled={!props.editable}
            />
            <div style={{ height: "1.75rem" }} />
        </div>
    );
};
const Useful = (props) => {
    const radioGroupData = [
        { label: "Yes", value: "Yes", className: "task blue" },
        { label: "No", value: "No", className: "task blue" },
    ];
    return (
        <div style={{ width: "33%" }}>
            <div style={mapping["forms/label/1-default"]}>Is this useful?</div>
            <RadioButtonGroup
                data={radioGroupData}
                defaultValue={props.is_useful ? "Yes" : "No"}
                layout={"vertical"}
                CustomOnChange={(event) => {
                    // eslint-disable-next-line no-console
                    props.handleUsefulChange(event.value);
                }}
                disabled={!props.editable}
            />
            <div style={{ height: "1.75rem" }} />
        </div>
    );
};
const Action = (props) => {
    //console.log(props.actions);
    return (
        <div
            style={{
                marginRight: "1.4375rem",
                // maxHeight: "30rem",
                // overflowY: "scroll"
                display: "inline-flex",
                width: "100%",
                // justifyContent: "space-between",
                flexWrap: "wrap",
            }}
            className={"scrollbar"}
        >
            {props.actions.map((action, index) => {
                let actionsContent = action.comment;
                if (action.comment_type === "Select Form") {
                    return (
                        <div
                            style={{
                                width: "33.75rem",
                                marginRight: "3.3rem",
                                height: "fit-content",
                            }}
                        >
                            <ActionDefaultTemplate
                                index={index}
                                action={action.comment_type}
                                data={["Text Field", "Upload Request"]}
                                selectedAction={action.comment_type}
                                handleDeleteAction={props.handleDeleteAction}
                                handleSelectAction={props.handleSelectAction}
                            />
                        </div>
                    );
                } else if (action.comment_type === "Text Field") {
                    return (
                        <div
                            style={{
                                width: "33.75rem",
                                marginRight: "3.3rem",
                                height: "fit-content",
                            }}
                        >
                            <ActionTextInput
                                index={index}
                                action={action.comment_type}
                                actionsContent={{ description: actionsContent }}
                                data={["Text Field", "Upload Request"]}
                                handleDeleteAction={props.handleDeleteAction}
                                handleSelectAction={props.handleSelectAction}
                                onChangeTextField={props.onChangeTextField}
                                disabled={!props.editable}
                            />
                        </div>
                    );
                } else if (action.comment_type === "Upload Request") {
                    return (
                        <div
                            style={{
                                width: "33.75rem",
                                marginRight: "3.3rem",
                                height: "fit-content",
                            }}
                        >
                            <ActionUploadRequest
                                index={index}
                                action={action.comment_type}
                                actionsContent={{ description: actionsContent }}
                                data={["Text Field", "Upload Request"]}
                                uploadingFile={action.attachments}
                                handleDeleteAction={props.handleDeleteAction}
                                handleSelectAction={props.handleSelectAction}
                                onChangeUploadRequest={props.onChangeTextField}
                                handleAttachmentChange={(e) => {
                                    props.handleAttachmentChange(index, e);
                                }}
                                handleRemoveUploadFile={(e) => {
                                    props.handleRemoveUploadFile(index, e);
                                }}
                                handleAttachmentDownload={
                                    props.handleAttachmentDownload
                                }
                                disabled={!props.editable}
                            />
                        </div>
                    );
                }
            })}
        </div>
    );
};
const OneItemPerLine = (props) => {
    return (
        <div style={{ width: "33%" }}>
            <div style={mapping["forms/label/1-default"]}>
                {props.data.title}
            </div>
            <div style={alertManagementStyle.contentStyle}>
                {props.data.content}
            </div>
            <div style={{ height: "1.75rem" }} />
        </div>
    );
};

const TwoItemPerLine = (props) => {
    return (
        <div style={{ display: "inline-flex", width: "33%" }}>
            {props.data.map((data) => {
                return (
                    <div>
                        <div style={mapping["forms/label/1-default"]}>
                            {data.title}
                        </div>
                        <div style={alertManagementStyle.shortContentStyle}>
                            {data.content}
                        </div>
                        <div style={{ height: "1.75rem" }} />
                    </div>
                );
            })}
        </div>
    );
};
const TextInput = (props) => {
    const onChange = (value) => {
        props.onCustomChange(value);
    };
    return (
        <AutoSizeTextArea
            minRows={props.minRows}
            fieldstyle={{
                ...alertManagementStyle.InputFieldStyle,
                width: props.width,
                height: props.height,
            }}
            value={props.value}
            CustomizedOnChange={onChange}
            disabled={props.disabled}
        />
    );
};
//
// const TransferRequestRejected = (props) => {
//     return (
//         <div style={{ width: alertManagementStyle.contentStyle.width }}>
//             <div
//                 style={{
//                     ...alertManagementStyle.messageWrapper,
//                     backgroundColor: "#281b25",
//                 }}
//             >
//                 <div
//                     style={{
//                         ...alertManagementStyle.messageStyle,
//                         backgroundColor: mapping["Color_Basic_Danger"],
//                     }}
//                 />
//                 <div>
//                     <div
//                         style={{
//                             ...alertManagementStyle.transferMessageTitleStyle,
//                             color: mapping["Color_Basic_Danger"],
//                         }}
//                     >
//                         {
//                             props.userList.filter((user) => {
//                                 return (
//                                     user.username === props.message.from_user
//                                 );
//                             })[0].display_name
//                         }{" "}
//                         rejected your alert transfer request
//                     </div>
//                     {props.message.to_user === props.currentUser.username && (
//                         <div
//                             style={{
//                                 ...mapping["forms/label/1-default"],
//                                 ...alertManagementStyle.messageCommentStyle,
//                             }}
//                         >
//                             "{props.message.comment}"
//                         </div>
//                     )}
//                 </div>
//             </div>
//             <div style={{ height: "1rem" }} />
//         </div>
//     );
// };
// const TransferRequestAccepted = (props) => {
//     return (
//         <div style={{ width: alertManagementStyle.contentStyle.width }}>
//             <div
//                 style={{
//                     ...alertManagementStyle.messageWrapper,
//                     backgroundColor: "#41825d32",
//                 }}
//             >
//                 <div
//                     style={{
//                         ...alertManagementStyle.messageStyle,
//                         backgroundColor: mapping["Color_Basic_Success"],
//                     }}
//                 />
//                 <div
//                     style={{
//                         ...alertManagementStyle.transferMessageTitleStyle,
//                         color: mapping["Color_Basic_Success"],
//                     }}
//                 >
//                     {
//                         props.userList.filter((user) => {
//                             return user.username === props.message.to_user;
//                         })[0].display_name
//                     }{" "}
//                     has accepted the alert transfer request
//                 </div>
//             </div>
//             <div style={{ height: "1rem" }} />
//         </div>
//     );
// };
// const TransferRequested = (props) => {
//     return (
//         <div style={{ width: alertManagementStyle.contentStyle.width }}>
//             <div
//                 style={{
//                     ...alertManagementStyle.messageWrapper,
//                     backgroundColor: "#112637",
//                 }}
//             >
//                 <div
//                     style={{
//                         ...alertManagementStyle.messageStyle,
//                         backgroundColor: mapping["Color_Basic_Primary"],
//                     }}
//                 />
//                 <div>
//                     <div style={alertManagementStyle.transferMessageTitleStyle}>
//                         Transfer request sent to{" "}
//                         {
//                             props.userList.filter((user) => {
//                                 return (
//                                     user.username === props.alert.transferees[0]
//                                 );
//                             })[0].display_name
//                         }
//                     </div>
//                     {props.message.from_user === props.currentUser.username && (
//                         <div
//                             style={{
//                                 ...mapping["forms/label/1-default"],
//                                 ...alertManagementStyle.messageCommentStyle,
//                             }}
//                         >
//                             "{props.message.comment}"
//                         </div>
//                     )}
//                 </div>
//             </div>
//             <div style={{ height: "1rem" }} />
//         </div>
//     );
// };
// const ReportAlertRequestAlertOwnerSide = (props) => {
//     let report_msg = props.msgList[props.msgList.length - 1];
//     let To_username = report_msg.to_user;
//     let To_user_display_name = props.userList.filter((user) => {
//         return user.username === To_username;
//     })[0].display_name;
//     return (
//         <div style={{ width: alertManagementStyle.contentStyle.width }}>
//             <div
//                 style={{
//                     ...alertManagementStyle.messageWrapper,
//                     backgroundColor: "#ffc1071B",
//                 }}
//             >
//                 <div
//                     style={{
//                         ...alertManagementStyle.messageStyle,
//                         backgroundColor: mapping["Color_Basic_Warning"],
//                     }}
//                 />
//                 <div>
//                     <div
//                         style={{
//                             ...alertManagementStyle.transferMessageTitleStyle,
//                             color: mapping["Color_Basic_Warning"],
//                         }}
//                     >
//                         this risk has been reported to {To_user_display_name}
//                     </div>
//                     <div
//                         style={{
//                             ...mapping["forms/label/1-default"],
//                             ...alertManagementStyle.messageCommentStyle,
//                         }}
//                     >
//                         "{report_msg.comment}"
//                     </div>
//                 </div>
//             </div>
//             <div style={{ height: "1rem" }} />
//         </div>
//     );
// };
// const ReportAlertRequest = (props) => {
//     return (
//         <div style={{ width: alertManagementStyle.contentStyle.width }}>
//             <div
//                 style={{
//                     ...alertManagementStyle.messageWrapper,
//                     backgroundColor: "#ffc1071B",
//                 }}
//             >
//                 <div
//                     style={{
//                         ...alertManagementStyle.messageStyle,
//                         backgroundColor: mapping["Color_Basic_Warning"],
//                     }}
//                 />
//                 <div>
//                     <div
//                         style={{
//                             ...alertManagementStyle.transferMessageTitleStyle,
//                             color: mapping["Color_Basic_Warning"],
//                         }}
//                     >
//                         {
//                             props.userList.filter((user) => {
//                                 return user.username === props.alert.creator;
//                             })[0].display_name
//                         }{" "}
//                         reported this alert to you
//                     </div>
//                     <div
//                         style={{
//                             ...mapping["forms/label/1-default"],
//                             ...alertManagementStyle.messageCommentStyle,
//                         }}
//                     >
//                         "{props.alert.transfer_messages[0].comment}"
//                     </div>
//                 </div>
//             </div>
//             <div style={{ height: "1rem" }} />
//         </div>
//     );
// };
// const ReceiveTransferRequest = (props) => {
//     return (
//         <div style={{ width: alertManagementStyle.contentStyle.width }}>
//             <div
//                 style={{
//                     ...alertManagementStyle.messageWrapper,
//                     backgroundColor: "#112637",
//                 }}
//             >
//                 <div
//                     style={{
//                         ...alertManagementStyle.messageStyle,
//                         backgroundColor: mapping["Color_Basic_Primary"],
//                     }}
//                 />
//                 <div>
//                     <div style={alertManagementStyle.transferMessageTitleStyle}>
//                         {
//                             props.userList.filter((user) => {
//                                 return (
//                                     user.username ===
//                                     props.alert.past_owners[
//                                         props.alert.past_owners.length - 1
//                                     ]
//                                 );
//                             })[0].display_name
//                         }{" "}
//                         has transferred the alert to you
//                     </div>
//                     {props.message.to_user === props.currentUser.username && (
//                         <div
//                             style={{
//                                 ...mapping["forms/label/1-default"],
//                                 ...alertManagementStyle.messageCommentStyle,
//                             }}
//                         >
//                             "{props.message.comment}"
//                         </div>
//                     )}
//                 </div>
//             </div>
//             <div
//                 style={{
//                     display: "inline-flex",
//                     justifyContent: "flex-end",
//                     width: "100%",
//                 }}
//             >
//                 <ButtonSolid
//                     name={"Decline"}
//                     color={"Danger"}
//                     clickEvent={props.handleDeclineTransferPopup}
//                 />
//                 <div style={{ width: "0.625rem" }} />
//                 <ButtonSolid
//                     name={"Accept"}
//                     color={"Success"}
//                     clickEvent={() => {
//                         props.handleConfirmation(
//                             "Accept Alert Transfer",
//                             "You are about to accept this alert transfer, you will become the owner of this alert",
//                             props.handleAcceptTransferAlert
//                         );
//                     }}
//                 />
//             </div>
//         </div>
//     );
// };

export { CaseManagement };
