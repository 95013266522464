import React from "react";
import {
    CounterparitiesChart,
    CustomerAnomalyDetectionChart,
    HistoricalTransactionsTrendingChart,
    RelationAlertChart,
} from "../../../component/chart";
import {
    PastAlertDetailsTables,
    PastTransactionDetailsTables,
} from "../../../component/table";
import { contentBackgroundColor, tabStyle } from "../../../../colorSchema";
import {
    PastAlertDetailsData,
    PastTransactionDetailsData,
} from "../../../../api/fakeData";

const chartBackgroundStyle = {
    backgroundColor: contentBackgroundColor,
    height: "18rem",
    width: "53rem",
    margin: "0.5rem",
    position: "relative",
};
const chartWrapper = {
    height: "15rem",
    width: "80%",
    margin: "auto",
    marginTop: "2.5rem",
};
const chartTitleStyle = {
    position: "absolute",
    let: "20px",
    top: "10px",
    marginLeft: "10px",
    color: "white",
};

class TransactionMonitoring extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps, nextContext) {}

    render() {
        return (
            <div className={"div_scroll"} style={tabStyle}>
                <RelationAlertChart
                    backgroundStyle={chartBackgroundStyle}
                    chartTitleStyle={chartTitleStyle}
                    chartWrapper={chartWrapper}
                />
                <CustomerAnomalyDetectionChart
                    backgroundStyle={chartBackgroundStyle}
                    chartTitleStyle={chartTitleStyle}
                    chartWrapper={chartWrapper}
                    vendorList={this.props.vendorList}
                />
                <HistoricalTransactionsTrendingChart
                    backgroundStyle={chartBackgroundStyle}
                    chartTitleStyle={chartTitleStyle}
                    chartWrapper={chartWrapper}
                />
                <CounterparitiesChart
                    backgroundStyle={chartBackgroundStyle}
                    chartTitleStyle={chartTitleStyle}
                    chartWrapper={chartWrapper}
                />
                <PastAlertDetailsTables data={PastAlertDetailsData} />
                <PastTransactionDetailsTables
                    data={PastTransactionDetailsData}
                />
            </div>
        );
    }
}

export { TransactionMonitoring };
