const getAlertList = () => {
    return [
        {
            id: "14",
            anomaly_score: "0.8",
            similarity: "80%",
            customer_id: "OE17000499",
            priority: "High",
            description: "Anomalous Vendor detected",
            owners: [],
            alert_date: "02/02/2021",
            due_date: "02/09/2021",
            actions: [],
            last_modified_time: "15:30",
            last_modified_date: "02/02/2021",
            status: "Open",
            transaction_id: [],
        },
        {
            id: "28",
            anomaly_score: "0.8",
            similarity: "80%",
            customer_id: "OE17000325",
            priority: "Low",
            description: "Suspected structuring and flowthrough",
            owners: ["kerime", "callincious"],
            alert_date: "02/07/2021",
            due_date: "05/31/2021",
            actions: [],
            last_modified_time: "15:30",
            last_modified_date: "02/18/2021",
            status: "In Progress",
            transaction_id: [
                "S011823",
                "S886718",
                "S299204",
                "S930223",
                "S294024",
                "S121412",
                "S995724",
                "S267663",
                "S267662",
                "S267661",
                "S289282",
                "S2690061",
                "S018661",
                "S285961",
                "S167321",
                "S392661",
                "S182911",
            ],
        },
        {
            id: "34",
            anomaly_score: "0.8",
            similarity: "80%",
            customer_id: "OE17000129",
            priority: "High",
            description: "Anomalous Vendor detected",
            owners: [],
            alert_date: "02/09/2021",
            due_date: "02/16/2021",
            actions: [],
            last_modified_time: "15:30",
            last_modified_date: "02/09/2021",
            status: "Open",
            transaction_id: [],
        },
        {
            id: "48",
            anomaly_score: "0.8",
            similarity: "80%",
            customer_id: "OE17000287",
            priority: "High",
            description: "Anomalous Vendor detected",
            owners: [],
            alert_date: "02/19/2021",
            due_date: "03/01/2021",
            actions: [],
            last_modified_time: "15:30",
            last_modified_date: "02/19/2021",
            status: "Open",
            transaction_id: [],
        },
    ];
};

const fakeUserList = [
    { username: "kerime", display_name: "Kerime Pratap", title: "Manager" },
    {
        username: "callincious",
        display_name: "Callinicus Bryony",
        title: "Manager",
    },
];

const getAlertByID = (id) => {
    return getAlertList().filter((a) => {
        return a.id === id;
    })[0];
};
const getTransactionList = () => {
    return [
        {
            id: "S011823",
            date: "02/25/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "3500.00",
            type: "CR",
            narration: "TPA-124pR123-INCOMING PAYMENT",
            counterparty: "WEST TAILORS",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S886718",
            date: "02/25/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "2500.00",
            type: "CR",
            narration: "CDS-030ht212-INCOMING PAYMENT",
            counterparty: "WEST TAILORS",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S299204",
            date: "02/26/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "1000.00",
            type: "DR",
            narration: "TPA-4992ji23-INCOMING PAYMENT",
            counterparty: "DAVID SMITH",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S930223",
            date: "02/27/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "600.00",
            type: "DR",
            narration: "TPA-0201ey32-INCOMING PAYMENT",
            counterparty: "DAVID SMITH",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S294024",
            date: "02/15/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "6000.00",
            type: "DR",
            narration: "TPA-0201ey32-INCOMING PAYMENT",
            counterparty: "  ABC MOTORS  ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S121412",
            date: "02/15/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "3000.00",
            type: "DR",
            narration: "TPA-0201ey32-INCOMING PAYMENT",
            counterparty: "TIM CARLOS",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S995724",
            date: "02/25/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "11000.00",
            type: "DR",
            narration: "TPA-0201ey32-INCOMING PAYMENT",
            counterparty: "        TIM CARLOS        ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S267663",
            date: "01/24/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "12000.00",
            type: "CR",
            narration: "TPA-232fc234-INCOMING PAYMENT",
            counterparty: "     XJSF PTE LTD     ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S267663",
            date: "01/25/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "4000.00",
            type: "CR",
            narration: "TPA-232fc234-INCOMING PAYMENT",
            counterparty: "XJSF PTE LTD",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S267662",
            date: "01/27/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "5000.00",
            type: "CR",
            narration: "CDS-60034354-INCOMING PAYMENT",
            counterparty: "XJSF PTE LTD",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S2690061",
            date: "01/28/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "6500.00",
            type: "CR",
            narration: "TPA-98yh0295-INCOMING PAYMENT",
            counterparty: "  XJSF PTE LTD  ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S267661",
            date: "01/29/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "8000.00",
            type: "CR",
            narration: "TPA-63ds3242-INCOMING PAYMENT",
            counterparty: "   XJSF PTE LTD   ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S018661",
            date: "02/12/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "7000.00",
            type: "CR",
            narration: "TPA-19ik9201-INCOMING PAYMENT",
            counterparty: "  XJSF PTE LTD  ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S182911",
            date: "02/17/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "4600.00",
            type: "CR",
            narration: "TPA-92sj7582-INCOMING PAYMENT",
            counterparty: " XJSF PTE LTD ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S392661",
            date: "02/19/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "3600.00",
            type: "CR",
            narration: "TPA-10la8492-INCOMING PAYMENT",
            counterparty: "XJSF PTE LTD",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S167321",
            date: "02/22/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "3000.00",
            type: "CR",
            narration: "TPA-27ng9104-INCOMING PAYMENT",
            counterparty: "XJSF PTE LTD",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
        {
            id: "S285961",
            date: "02/25/2021",
            customer_id: "OE17000499",
            account_id: "OE170004990002",
            currency: "SGD",
            amount: "11000.00",
            type: "CR",
            narration: "TPA-47uj7391-INCOMING PAYMENT",
            counterparty: "     XJSF PTE LTD     ",
            transubtype: "TRANSFER",
            jurisdiction: "Y",
        },
    ];
};

const getTransactionByAlertID = (alert_id) => {
    let alertList = getAlertList();
    let transactionIDList = alertList.filter((a) => {
        return a.id === alert_id;
    })[0].transaction_id;
    let transactionList = getTransactionList();
    return transactionList.filter((t) => {
        return transactionIDList.includes(t.id);
    });
};

const headerMapping = {
    homeView: "Home View",
    overview: "Overview",
    structuring: "Structuring",
    flowThrough: "Flow Through",
    KYC: "Alert Overview, KYC",
    sanction: "Sanction",
    transactionMonitoring: "Transaction Monitoring",
};

const tabMapping = {
    alert_overview: "Alert Overview",
    customer: "Customer",
    case_management: "Case Management",
};

const alertOverviewDropDownMapping = {
    overview: "Overview",
    redFlag: "Red Flags",
};
const alertOverviewDropDownRedFlagMapping = {
    structuring: "Structuring (90%)",
    flowThrough: "Flow Through (95%)",
    similarity: "Similarity to past STRs (80%)",
    profileDeviation: "Deviation from customer profile (15%)",
    anomalous: "Anomalous Patterns (10%)",
    clusterDeviation: "Deviation from customers in the same cluster (7%)",
    MTOOTM: "Many to one / One to many (5%)",
};

const alertOverviewDropDownTreeData = [
    {
        text: alertOverviewDropDownMapping.overview,
        id: 1,
        expanded: false,
    },
    {
        text: alertOverviewDropDownMapping.redFlag,
        id: 2,
        expanded: false,
        items: [
            { text: alertOverviewDropDownRedFlagMapping.structuring, id: 3 },
            { text: alertOverviewDropDownRedFlagMapping.flowThrough, id: 4 },
            { text: alertOverviewDropDownRedFlagMapping.similarity, id: 5 },
            {
                text: alertOverviewDropDownRedFlagMapping.profileDeviation,
                id: 6,
            },
            { text: alertOverviewDropDownRedFlagMapping.anomalous, id: 7 },
            {
                text: alertOverviewDropDownRedFlagMapping.clusterDeviation,
                id: 8,
            },
            { text: alertOverviewDropDownRedFlagMapping.MTOOTM, id: 9 },
        ],
    },
];

const customerTabMapping = {
    KYC: "KYC",
    sanction: "Sanction",
    tm: "Transaction Monitoring",
};

const customerInfoHeaderMapping = {
    full_name: "Full Name",
    pep: "Current or Former PEP",
    aliases: "Aliases (if any)",
    aliases_name: "If yes, please state",
    identity: "Identity No.",
    gender: "Gender",
    marital: "Marital Status",
    date: "Date of Birth",
    place: "Place of Birth",
    nationality: "Nationality",
    occupation: "Occupation",
    industry: "Industry",
    mobile_number: "Mobile Number",
    residential_address: "Residential Address",
    expected_aum: "Expected AUM",
    current_aum: "Current AUM",
    account_opening_date: "Account Opening Date",
    relationship_manager: "Relationship Manager",
    source_of_wealth: "Source of Wealth",
};
const customerInfoContent = {
    full_name: "Jane Margaret Doe",
    pep: "N",
    aliases: "N",
    aliases_name: "NA",
    identity: "U123456K",
    gender: "F",
    marital: "Single",
    date: "12-12-1960",
    place: "US",
    nationality: "US",
    occupation: "Director",
    industry: "Automobile",
    mobile_number: "12345678",
    residential_address: "7 Board Street #12-01",
    expected_aum: "$15M",
    current_aum: "$10M",
    account_opening_date: "01/10/2019",
    relationship_manager: "John Wick",
    source_of_wealth: "Jane Doe’s source of\nwealth was from…",
};
const sanctionNews = [
    {
        title: "Recent Police Arrest: 329 Suspected Scammers and Money Mules​",
        date: "8 Oct 2019 11:21:08 GST​",
        content: "On the night of 7 Oct 2019, police raided multiple joints….​",
    },
    {
        title: "Luxury Cars among $100M of Assets Seized from Director Involved in Alleged Fraud",
        date: "8 Oct 2019 11:21:08 GST​",
        content: "On the night of 7 Oct 2019, police raided multiple joints….​",
    },
];

const fakeVendorAnalysisData = [
    {
        id: 145,
        Vendor: "100000083",
        vendor_name: "Prohaska LLC",
        vendor_cluster: 3,
        total_PO_amount: 2138,
        total_PO_quantity: 20,
        no_of_po: 3,
        mad_vendor: -0.3666821718,
        mad_cluster: -0.5530344844,
        no_of_createdby: 1,
        no_of_approvedby: 2,
        percent_round_value: 0,
        percent_created_nwh: 0,
        percent_approved_nwh: 0,
        no_of_po_pertime: 0.6000000238,
        no_of_po_per_time_std: 0.3000000119,
        no_of_similar_add: 0,
        PCA1: 2.5660152435,
        PCA2: 1.3624365032,
        IsFraud: 0,
        keywords: "envelope,window,ref,print",
        score_for_mad_cluster: 2.89,
        score_for_mad_vendor: 32.53,
        score_for_no_of_po: 35.57,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 32.04,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 2.62,
        score_for_no_of_po_per_time_std: 96.36,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 149,
        Vendor: "100000155",
        vendor_name: "Schoen, Grant and Dickinson",
        vendor_cluster: 3,
        total_PO_amount: 1074.0899658203,
        total_PO_quantity: 586,
        no_of_po: 7,
        mad_vendor: -0.3697616458,
        mad_cluster: -0.5477690101,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0,
        percent_created_nwh: 0.3333333433,
        percent_approved_nwh: 0.3333333433,
        no_of_po_pertime: 0.875,
        no_of_po_per_time_std: 0.125,
        no_of_similar_add: 0,
        PCA1: 2.6960179805999998,
        PCA2: 3.3041753769,
        IsFraud: 0,
        keywords:
            "gel,fine,note,pilot,post,pad,pen,col,stapler,pink,correction,tape,beige,neon,swan,eraser,grey,soft,thumb,drive,plastic,ruler,small,refill,blue",
        score_for_mad_cluster: 3.13,
        score_for_mad_vendor: 12.18,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 2.86,
        score_for_no_of_po_per_time_std: 93.61,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 151,
        Vendor: "100000196",
        vendor_name: "Wisoky Inc",
        vendor_cluster: 3,
        total_PO_amount: 83942.71875,
        total_PO_quantity: 3,
        no_of_po: 3,
        mad_vendor: -0.1873224378,
        mad_cluster: 0.5352067351,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0,
        percent_created_nwh: 0.6666666865,
        percent_approved_nwh: 0.6666666865,
        no_of_po_pertime: 3,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 1,
        PCA1: 2.2441176175999997,
        PCA2: 1.4970076978,
        IsFraud: 0,
        keywords: "array,tape,maintenance,server",
        score_for_mad_cluster: 9.82,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 35.57,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 14.16,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 68.27,
    },
    {
        id: 154,
        Vendor: "100000230",
        vendor_name: "Keeling LLC",
        vendor_cluster: 3,
        total_PO_amount: 12340,
        total_PO_quantity: 996,
        no_of_po: 5,
        mad_vendor: -0.3687101305,
        mad_cluster: -0.5482985973,
        no_of_createdby: 2,
        no_of_approvedby: 3,
        percent_round_value: 0,
        percent_created_nwh: 0.375,
        percent_approved_nwh: 0.25,
        no_of_po_pertime: 1.25,
        no_of_po_per_time_std: 2.25,
        no_of_similar_add: 0,
        PCA1: 2.1222565174000003,
        PCA2: 1.1448189765,
        IsFraud: 0,
        keywords: "lunch,dinner,value,buffet,national,end,day,sla,year,service",
        score_for_mad_cluster: 3.09,
        score_for_mad_vendor: 22.77,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 36.93,
        score_for_no_of_approvedby: 40.69,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 4.64,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 180,
        Vendor: "100000995",
        vendor_name: "Nolan LLC",
        vendor_cluster: 3,
        total_PO_amount: 30321,
        total_PO_quantity: 1,
        no_of_po: 1,
        mad_vendor: -0.3705878258,
        mad_cluster: 0.9353111386,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0,
        percent_created_nwh: 0,
        percent_approved_nwh: 0,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.1889612675,
        PCA2: 1.4868475497,
        IsFraud: 0,
        keywords: "fire,sun,period,prod",
        score_for_mad_cluster: 12.88,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 192,
        Vendor: "100001382",
        vendor_name: "Block, Fadel and Ryan",
        vendor_cluster: 3,
        total_PO_amount: 2550,
        total_PO_quantity: 261,
        no_of_po: 3,
        mad_vendor: -0.3690856695,
        mad_cluster: -0.5478402972,
        no_of_createdby: 2,
        no_of_approvedby: 3,
        percent_round_value: 0,
        percent_created_nwh: 0,
        percent_approved_nwh: 0.1428571492,
        no_of_po_pertime: 0.3000000119,
        no_of_po_per_time_std: 0.2333333343,
        no_of_similar_add: 0,
        PCA1: 2.6167401075,
        PCA2: 1.5357635021,
        IsFraud: 0,
        keywords: "head,reception,tea,pax,lunch,buffet,provision",
        score_for_mad_cluster: 3.13,
        score_for_mad_vendor: 19.38,
        score_for_no_of_po: 35.57,
        score_for_no_of_createdby: 36.93,
        score_for_no_of_approvedby: 40.69,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 64.8,
        score_for_no_of_po_pertime: 2.95,
        score_for_no_of_po_per_time_std: 96.96,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 197,
        Vendor: "100001614",
        vendor_name: "Renner, Dare and Conn",
        vendor_cluster: 3,
        total_PO_amount: 304.3999938965,
        total_PO_quantity: 197,
        no_of_po: 5,
        mad_vendor: -0.3697428703,
        mad_cluster: -0.5477881432,
        no_of_createdby: 2,
        no_of_approvedby: 2,
        percent_round_value: 0,
        percent_created_nwh: 0.200000003,
        percent_approved_nwh: 0.200000003,
        no_of_po_pertime: 0.4166666567,
        no_of_po_per_time_std: 0.4469696879,
        no_of_similar_add: 0,
        PCA1: 1.1475583464,
        PCA2: 2.2200167179,
        IsFraud: 0,
        keywords:
            "note,post,correction,tape,bar,duster,sharpener,magnetic,table,col,refill,pad,pen,yellow",
        score_for_mad_cluster: 3.13,
        score_for_mad_vendor: 12.41,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 36.93,
        score_for_no_of_approvedby: 32.04,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 2.81,
        score_for_no_of_po_per_time_std: 70.54,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 218,
        Vendor: "100001921",
        vendor_name: "Fadel-Heidenreich",
        vendor_cluster: 3,
        total_PO_amount: 47216,
        total_PO_quantity: 42,
        no_of_po: 1,
        mad_vendor: 3.7633988857,
        mad_cluster: -0.2978985608,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0.5,
        percent_created_nwh: 0.1666666716,
        percent_approved_nwh: 0.2083333284,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.2517797947,
        PCA2: 1.8627477884,
        IsFraud: 0,
        keywords: "item,accommodation,optional,mandatory",
        score_for_mad_cluster: 1.46,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 37.88,
        score_for_percent_created_nwh: 17,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 223,
        Vendor: "100002006",
        vendor_name: "Goodwin Group",
        vendor_cluster: 3,
        total_PO_amount: 790403,
        total_PO_quantity: 16,
        no_of_po: 16,
        mad_vendor: 0.4593640566,
        mad_cluster: 2.390393734,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0.5,
        percent_created_nwh: 0.625,
        percent_approved_nwh: 0.5625,
        no_of_po_pertime: 4,
        no_of_po_per_time_std: 16.6666660309,
        no_of_similar_add: 1,
        PCA1: 18.6615409851,
        PCA2: 8.143877029399999,
        IsFraud: 1,
        keywords:
            "server,period,sun,window,internal,backup,legacy,maintenance,hardware,add,cell,manager,network,renewal",
        score_for_mad_cluster: 20.19,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 37.88,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 19.39,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 68.27,
    },
    {
        id: 225,
        Vendor: "100002012",
        vendor_name: "Jacobson, Yost and Dare",
        vendor_cluster: 3,
        total_PO_amount: 728550,
        total_PO_quantity: 15,
        no_of_po: 15,
        mad_vendor: 0.2302823067,
        mad_cluster: 2.4059252739,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0.6000000238,
        percent_created_nwh: 0.8000000119,
        percent_approved_nwh: 0.8000000119,
        no_of_po_pertime: 3.75,
        no_of_po_per_time_std: 6.9166665077,
        no_of_similar_add: 1,
        PCA1: 13.2086191177,
        PCA2: 1.326479733,
        IsFraud: 1,
        keywords:
            "server,period,prod,window,maintenance,backup,legacy,instant,cell,manager,internal,recovery,hardware,add,renewal",
        score_for_mad_cluster: 20.29,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 18.07,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 68.27,
    },
    {
        id: 234,
        Vendor: "100002077",
        vendor_name: "Kautzer-Volkman",
        vendor_cluster: 3,
        total_PO_amount: 1600,
        total_PO_quantity: 3,
        no_of_po: 1,
        mad_vendor: -0.3705878258,
        mad_cluster: -0.5731492043,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 1,
        percent_created_nwh: 1,
        percent_approved_nwh: 0.5,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.2903859615,
        PCA2: 1.4407761991,
        IsFraud: 0,
        keywords:
            "alter,director,good,mad,vanish,wardrobe,cabinet,remove,install,table,exist,supply",
        score_for_mad_cluster: 1.04,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 260,
        Vendor: "100002185",
        vendor_name: "Feest-Bergstrom",
        vendor_cluster: 3,
        total_PO_amount: 1500,
        total_PO_quantity: 1,
        no_of_po: 1,
        mad_vendor: -0.3705878258,
        mad_cluster: -0.5323494673,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 1,
        percent_created_nwh: 0.2142857164,
        percent_approved_nwh: 0.3571428657,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.2975730896,
        PCA2: 1.4336954653,
        IsFraud: 0,
        keywords: "actuator,valve,replacement",
        score_for_mad_cluster: 4.23,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 271,
        Vendor: "100002206",
        vendor_name: "Bergnaum, Romaguera and Maggio",
        vendor_cluster: 3,
        total_PO_amount: 570,
        total_PO_quantity: 2,
        no_of_po: 1,
        mad_vendor: -0.2166148573,
        mad_cluster: -0.5622006655,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0,
        percent_created_nwh: 0,
        percent_approved_nwh: 0,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.3144812584,
        PCA2: 1.4112001956,
        IsFraud: 0,
        keywords: "armchair,desi,provide,transport,new,deliver",
        score_for_mad_cluster: 2.2,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 277,
        Vendor: "100002238",
        vendor_name: "Lebsack, Dickinson and Watsica",
        vendor_cluster: 3,
        total_PO_amount: 13750,
        total_PO_quantity: 500,
        no_of_po: 1,
        mad_vendor: -0.352186203,
        mad_cluster: -0.5490879416,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0,
        percent_created_nwh: 0.6666666865,
        percent_approved_nwh: 0.3333333433,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.3019509315,
        PCA2: 1.4030669630000001,
        IsFraud: 0,
        keywords: "mandatory,item,flow,free,juice,food,buffet,price,ring",
        score_for_mad_cluster: 3.03,
        score_for_mad_vendor: 83.7,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 292,
        Vendor: "100002282",
        vendor_name: "Bergstrom-Hickle",
        vendor_cluster: 3,
        total_PO_amount: 2600,
        total_PO_quantity: 650,
        no_of_po: 1,
        mad_vendor: -0.3705878258,
        mad_cluster: -0.5478912592,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0,
        percent_created_nwh: 1,
        percent_approved_nwh: 0,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.3039124012,
        PCA2: 1.4106254578,
        IsFraud: 0,
        keywords: "style,portrait,print,supply",
        score_for_mad_cluster: 3.12,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
    {
        id: 304,
        Vendor: "100002300",
        vendor_name: "Huels, Torphy and Effertz",
        vendor_cluster: 3,
        total_PO_amount: 2930,
        total_PO_quantity: 1,
        no_of_po: 1,
        mad_vendor: -0.3705878258,
        mad_cluster: -0.4595291317,
        no_of_createdby: 1,
        no_of_approvedby: 1,
        percent_round_value: 0,
        percent_created_nwh: 0.5,
        percent_approved_nwh: 0,
        no_of_po_pertime: 1,
        no_of_po_per_time_std: 0,
        no_of_similar_add: 0,
        PCA1: 4.301721096,
        PCA2: 1.4087092578,
        IsFraud: 0,
        keywords: "varnish,folder",
        score_for_mad_cluster: 4.16,
        score_for_mad_vendor: 1,
        score_for_no_of_po: 1,
        score_for_no_of_createdby: 1,
        score_for_no_of_approvedby: 1,
        score_for_percent_round_value: 1,
        score_for_percent_created_nwh: 1,
        score_for_percent_approved_nwh: 1,
        score_for_no_of_po_pertime: 1,
        score_for_no_of_po_per_time_std: 1,
        score_for_no_of_similar_add: 1,
    },
];

const PastAlertDetailsData = [
    {
        id: 2,
        customer_id: "CE17000499",
        priority: "High",
        alert_date: "06/21/2020",
        last_modified_date: "07/03/2020",
        status: "closed",
    },
    {
        id: 4,
        customer_id: "CE17000499",
        priority: "High",
        alert_date: "12/06/2020",
        last_modified_date: "12/09/2020",
        status: "closed",
    },
    {
        id: 5,
        customer_id: "CE17000499",
        priority: "High",
        alert_date: "12/06/2020",
        last_modified_date: "12/19/2020",
        status: "closed",
    },
];

const PastTransactionDetailsData = [
    {
        date: "03/10/2020",
        id: "S771983",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "USD",
        amount: "8000",
        type: "CR",
        narration: "TPA-293k3031-INCOMING PAYMENT",
        counterparty: "JEFFREY’S BAKERY",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "03/29/2020",
        id: "S267662",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "320",
        type: "DR",
        narration: "CDS-60034354-OUTGOING PAYMENT",
        counterparty: "SCOTT PENN",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "05/27/2020",
        id: "S267661",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "3023",
        type: "CR",
        narration: "TPA-63ds3242-INCOMING PAYMENT",
        counterparty: "ANNA WITHERFALL",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "06/20/2020",
        id: "S001284",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "USD",
        amount: "14024",
        type: "CR",
        narration: "TPA-251he451-INCOMING PAYMENT",
        counterparty: "EYR PTE LTD",
        transubtype: "TRANSFER",
        jurisdiction: "Y",
    },
    {
        date: "06/20/2020",
        id: "S029774",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "USD",
        amount: "15012",
        type: "CR",
        narration: "TPA-252gge55-INCOMING PAYMENT",
        counterparty: "EYR PTE LTD",
        transubtype: "TRANSFER",
        jurisdiction: "Y",
    },
    {
        date: "12/05/2020",
        id: "S995273",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "1000",
        type: "DR",
        narration: "TPA-293k3031-OUTGOING PAYMENT",
        counterparty: "KYLE WICK",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "12/05/2021",
        id: "S158832",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "1000",
        type: "DR",
        narration: "TPA-4992ji223-OUTGOING PAYMENT",
        counterparty: "KYLE WICK",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "12/05/2021",
        id: "S157739",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "1000",
        type: "DR",
        narration: "TPA-42995pe2-OUTGOING PAYMENT",
        counterparty: "KYLE WICK",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "12/05/2021",
        id: "S582735",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "1000",
        type: "DR",
        narration: "TPA-oje40285-OUTGOING PAYMENT",
        counterparty: "KYLE WICK",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "12/06/2021",
        id: "S002752",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "50",
        type: "CR",
        narration: "TPA-0201ey32-INCOMING PAYMENT",
        counterparty: "TIM CARLOS",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
    {
        date: "12/06/2021",
        id: "S185891",
        customer_id: "OE17000499",
        account_id: "OE170004990002",
        currency: "SGD",
        amount: "512",
        type: "CR",
        narration: "TPA-017iigw35-INCOMING PAYMENT",
        counterparty: "TIM CARLOS",
        transubtype: "TRANSFER",
        jurisdiction: "N",
    },
];

const overallModelIntegrityData = {
    labels: [
        "02/01/2021",
        "02/02/2021",
        "02/03/2021",
        "02/04/2021",
        "02/05/2021",
        "02/06/2021",
        "02/07/2021",
        "02/08/2021",
        "02/09/2021",
        "02/10/2021",
        "02/11/2021",
        "02/12/2021",
        "02/13/2021",
        "02/14/2021",
        "02/15/2021",
        "02/16/2021",
        "02/17/2021",
        "02/18/2021",
        "02/19/2021",
        "02/20/2021",
        "02/21/2021",
        "02/22/2021",
        "02/23/2021",
    ],
    datasets: [
        {
            type: "line",
            label: "False Positive",
            data: [
                5.3, 9.8, 6.4, 5.5, 4.2, 12.5, 8.7, 3.4, 3.8, 3.9, 9.2, 9.5,
                8.3, 7.5, 6.8, 6.9, 9.3, 8.4, 9.4, 5.6, 7.4, 7.6, 7.4,
            ],
            fill: false,
            backgroundColor: "#8DC6BF",
            borderColor: "#8DC6BF",
            hoverBackgroundColor: "#8DC6BF",
            hoverBorderColor: "#8DC6BF",
            yAxisID: "y-axis-1",
        },
        {
            label: "False Negative",
            type: "line",
            data: [
                0.1, 0.1, 0.4, 0.2, 0.2, 0.5, 0.1, 0.05, 0.3, 0.2, 0.1, 0.02,
                0.1, 0.1, 0.1, 0.1, 0.3, 0.2, 0.1, 0.1, 0.02, 0.03, 0.3,
            ],
            fill: false,
            borderColor: "#EC932F",
            backgroundColor: "#EC932F",
            pointBorderColor: "#EC932F",
            pointBackgroundColor: "#EC932F",
            pointHoverBackgroundColor: "#EC932F",
            pointHoverBorderColor: "#EC932F",
            yAxisID: "y-axis-2",
        },
    ],
};

const overallModelIntegrityGoodnessData = [
    {
        title: "Monthly Income",
        fit_test: "Failed",
        p_value: "0.0007",
        data: {
            labels: [
                "<$2K",
                "$2K-4K",
                "$4K-6K",
                "$6K-8K",
                "      $8K-10K",
                ">$10K",
            ],
            datasets: [
                {
                    type: "line",
                    label: "Training",
                    data: [3, 45, 24, 10, 5, 4],
                    fill: false,
                    backgroundColor: "#8DC6BF",
                    borderColor: "#8DC6BF",
                    hoverBackgroundColor: "#8DC6BF",
                    hoverBorderColor: "#8DC6BF",
                    yAxisID: "y-axis-1",
                },
                {
                    label: "Live",
                    type: "line",
                    data: [7, 60, 20, 6, 2, 3],
                    fill: false,
                    borderColor: "#EC932F",
                    backgroundColor: "#EC932F",
                    pointBorderColor: "#EC932F",
                    pointBackgroundColor: "#EC932F",
                    pointHoverBackgroundColor: "#EC932F",
                    pointHoverBorderColor: "#EC932F",
                    yAxisID: "y-axis-2",
                },
            ],
        },
    },
    {
        title: "Saving",
        fit_test: "Failed",
        p_value: "0.0007",
        data: {
            labels: [
                "<$10K",
                "$10K-50K",
                "$50K-100K",
                "$100K-500K",
                "$500K-1M",
                ">$1M",
            ],
            datasets: [
                {
                    type: "line",
                    label: "Training",
                    data: [3, 10, 20, 30, 20, 4],
                    fill: false,
                    backgroundColor: "#8DC6BF",
                    borderColor: "#8DC6BF",
                    hoverBackgroundColor: "#8DC6BF",
                    hoverBorderColor: "#8DC6BF",
                    yAxisID: "y-axis-1",
                },
                {
                    label: "Live",
                    type: "line",
                    data: [7, 20, 30, 20, 10, 3],
                    fill: false,
                    borderColor: "#EC932F",
                    backgroundColor: "#EC932F",
                    pointBorderColor: "#EC932F",
                    pointBackgroundColor: "#EC932F",
                    pointHoverBackgroundColor: "#EC932F",
                    pointHoverBorderColor: "#EC932F",
                    yAxisID: "y-axis-2",
                },
            ],
        },
    },
];

const supervisedIntegrityData = {
    labels: [
        "02/01/2021",
        "02/02/2021",
        "02/03/2021",
        "02/04/2021",
        "02/05/2021",
        "02/06/2021",
        "02/07/2021",
        "02/08/2021",
        "02/09/2021",
        "02/10/2021",
        "02/11/2021",
        "02/12/2021",
        "02/13/2021",
        "02/14/2021",
        "02/15/2021",
        "02/16/2021",
        "02/17/2021",
        "02/18/2021",
        "02/19/2021",
        "02/20/2021",
        "02/21/2021",
        "02/22/2021",
        "02/23/2021",
    ],
    datasets: [
        {
            type: "line",
            label: "False Positive",
            data: [
                5.3, 9.8, 6.4, 5.5, 4.2, 4.2, 8.7, 3.4, 11.4, 3.9, 9.2, 9.5,
                14.2, 7.5, 6.8, 6.9, 9.3, 8.4, 9.4, 15.3, 6.3, 7.6, 12.9,
            ],
            fill: false,
            backgroundColor: "#8DC6BF",
            borderColor: "#8DC6BF",
            hoverBackgroundColor: "#8DC6BF",
            hoverBorderColor: "#8DC6BF",
            yAxisID: "y-axis-1",
        },
        {
            label: "False Negative",
            type: "line",
            data: [
                0.1, 0.1, 0.4, 0.2, 0.2, 0.5, 0.1, 0.05, 0.3, 0.2, 0.1, 0.02,
                0.1, 0.1, 0.1, 0.1, 0.3, 0.2, 0.1, 0.1, 0.02, 0.03, 0.3,
            ],
            fill: false,
            borderColor: "#EC932F",
            backgroundColor: "#EC932F",
            pointBorderColor: "#EC932F",
            pointBackgroundColor: "#EC932F",
            pointHoverBackgroundColor: "#EC932F",
            pointHoverBorderColor: "#EC932F",
            yAxisID: "y-axis-2",
        },
    ],
};

const supervisedIntegrityGoodnessData = [
    {
        title: "Monthly Income",
        fit_test: "Failed",
        p_value: "0.0007",
        data: {
            labels: [
                "<$2K",
                "$2K-4K",
                "$4K-6K",
                "$6K-8K",
                "      $8K-10K",
                ">$10K",
            ],
            datasets: [
                {
                    type: "line",
                    label: "Training",
                    data: [3, 45, 24, 10, 5, 4],
                    fill: false,
                    backgroundColor: "#8DC6BF",
                    borderColor: "#8DC6BF",
                    hoverBackgroundColor: "#8DC6BF",
                    hoverBorderColor: "#8DC6BF",
                    yAxisID: "y-axis-1",
                },
                {
                    label: "Live",
                    type: "line",
                    data: [7, 60, 20, 6, 30, 3],
                    fill: false,
                    borderColor: "#EC932F",
                    backgroundColor: "#EC932F",
                    pointBorderColor: "#EC932F",
                    pointBackgroundColor: "#EC932F",
                    pointHoverBackgroundColor: "#EC932F",
                    pointHoverBorderColor: "#EC932F",
                    yAxisID: "y-axis-2",
                },
            ],
        },
    },
    {
        title: "Saving",
        fit_test: "Failed",
        p_value: "0.0007",
        data: {
            labels: [
                "<$10K",
                "$10K-50K",
                "$50K-100K",
                "$100K-500K",
                "$500K-1M",
                ">$1M",
            ],
            datasets: [
                {
                    type: "line",
                    label: "Training",
                    data: [3, 10, 20, 10, 20, 4],
                    fill: false,
                    backgroundColor: "#8DC6BF",
                    borderColor: "#8DC6BF",
                    hoverBackgroundColor: "#8DC6BF",
                    hoverBorderColor: "#8DC6BF",
                    yAxisID: "y-axis-1",
                },
                {
                    label: "Live",
                    type: "line",
                    data: [7, 20, 30, 73, 10, 3],
                    fill: false,
                    borderColor: "#EC932F",
                    backgroundColor: "#EC932F",
                    pointBorderColor: "#EC932F",
                    pointBackgroundColor: "#EC932F",
                    pointHoverBackgroundColor: "#EC932F",
                    pointHoverBorderColor: "#EC932F",
                    yAxisID: "y-axis-2",
                },
            ],
        },
    },
];

const unsupervisedIntegrityData = {
    labels: [
        "02/01/2021",
        "02/02/2021",
        "02/03/2021",
        "02/04/2021",
        "02/05/2021",
        "02/06/2021",
        "02/07/2021",
        "02/08/2021",
        "02/09/2021",
        "02/10/2021",
        "02/11/2021",
        "02/12/2021",
        "02/13/2021",
        "02/14/2021",
        "02/15/2021",
        "02/16/2021",
        "02/17/2021",
        "02/18/2021",
        "02/19/2021",
        "02/20/2021",
        "02/21/2021",
        "02/22/2021",
        "02/23/2021",
    ],
    datasets: [
        {
            type: "line",
            label: "False Positive",
            data: [
                12.7, 6.3, 6.4, 5.5, 4.2, 8.5, 8.7, 2.4, 8.3, 12.9, 9.2, 9.5,
                8.3, 7.5, 6.8, 6.9, 9.3, 8.4, 9.4, 5.6, 7.4, 7.6, 7.4,
            ],
            fill: false,
            backgroundColor: "#8DC6BF",
            borderColor: "#8DC6BF",
            hoverBackgroundColor: "#8DC6BF",
            hoverBorderColor: "#8DC6BF",
            yAxisID: "y-axis-1",
        },
        {
            label: "False Negative",
            type: "line",
            data: [
                0.1, 0.1, 0.4, 0.2, 0.2, 0.5, 0.1, 0.05, 0.3, 0.2, 0.1, 0.02,
                0.1, 0.1, 0.1, 0.1, 0.3, 0.2, 0.1, 0.1, 0.02, 0.03, 0.3,
            ],
            fill: false,
            borderColor: "#EC932F",
            backgroundColor: "#EC932F",
            pointBorderColor: "#EC932F",
            pointBackgroundColor: "#EC932F",
            pointHoverBackgroundColor: "#EC932F",
            pointHoverBorderColor: "#EC932F",
            yAxisID: "y-axis-2",
        },
    ],
};

const unsupervisedIntegrityGoodnessData = [
    {
        title: "Monthly Income",
        fit_test: "Passed",
        p_value: "0.02",
        data: {
            labels: [
                "<$2K",
                "$2K-4K",
                "$4K-6K",
                "$6K-8K",
                "      $8K-10K",
                ">$10K",
            ],
            datasets: [
                {
                    type: "line",
                    label: "Training",
                    data: [2, 78, 36, 5, 9, 4],
                    fill: false,
                    backgroundColor: "#8DC6BF",
                    borderColor: "#8DC6BF",
                    hoverBackgroundColor: "#8DC6BF",
                    hoverBorderColor: "#8DC6BF",
                    yAxisID: "y-axis-1",
                },
                {
                    label: "Live",
                    type: "line",
                    data: [13, 77, 31, 4, 8, 3],
                    fill: false,
                    borderColor: "#EC932F",
                    backgroundColor: "#EC932F",
                    pointBorderColor: "#EC932F",
                    pointBackgroundColor: "#EC932F",
                    pointHoverBackgroundColor: "#EC932F",
                    pointHoverBorderColor: "#EC932F",
                    yAxisID: "y-axis-2",
                },
            ],
        },
    },
    {
        title: "Saving",
        fit_test: "Passed",
        p_value: "0.02",
        data: {
            labels: [
                "<$10K",
                "$10K-50K",
                "$50K-100K",
                "$100K-500K",
                "$500K-1M",
                ">$1M",
            ],
            datasets: [
                {
                    type: "line",
                    label: "Training",
                    data: [8, 12, 46, 31, 15, 4],
                    fill: false,
                    backgroundColor: "#8DC6BF",
                    borderColor: "#8DC6BF",
                    hoverBackgroundColor: "#8DC6BF",
                    hoverBorderColor: "#8DC6BF",
                    yAxisID: "y-axis-1",
                },
                {
                    label: "Live",
                    type: "line",
                    data: [9, 19, 49, 28, 18, 3],
                    fill: false,
                    borderColor: "#EC932F",
                    backgroundColor: "#EC932F",
                    pointBorderColor: "#EC932F",
                    pointBackgroundColor: "#EC932F",
                    pointHoverBackgroundColor: "#EC932F",
                    pointHoverBorderColor: "#EC932F",
                    yAxisID: "y-axis-2",
                },
            ],
        },
    },
];

export {
    getAlertList,
    getAlertByID,
    getTransactionList,
    getTransactionByAlertID,
    headerMapping,
    tabMapping,
    alertOverviewDropDownMapping,
    alertOverviewDropDownRedFlagMapping,
    customerTabMapping,
    customerInfoHeaderMapping,
    customerInfoContent,
    sanctionNews,
    fakeVendorAnalysisData,
    PastAlertDetailsData,
    PastTransactionDetailsData,
    overallModelIntegrityData,
    overallModelIntegrityGoodnessData,
    supervisedIntegrityData,
    supervisedIntegrityGoodnessData,
    unsupervisedIntegrityData,
    unsupervisedIntegrityGoodnessData,
    fakeUserList,
    alertOverviewDropDownTreeData,
};
